import Layout from "layout/Layout";
import ContactUs from "./ContactUs";
import Header from "./Header";

function Landing() {
  return (
    <Layout>
      <Header />
      <ContactUs />
    </Layout>
  );
}

export default Landing;
