import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as HomeActive } from "assets/icons/dashboardActive.svg";
import { ReactComponent as ProfileActive } from "assets/icons/profileActive.svg";
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import { ReactComponent as Transaction } from "assets/icons/transaction.svg";
import { ReactComponent as TransactionActive } from "assets/icons/transactionActive.svg";
import { ReactComponent as ContactUs } from "assets/icons/contact-us.svg";
import { ReactComponent as ContactUsActive } from "assets/icons/contact-us.svg";
import { ReactComponent as Electric } from "assets/icons/electricity.svg";
import { ReactComponent as ElectricActive } from "assets/icons/electricityActive.svg";
import styles from './Dashboard.module.scss'

export const dashboardLiks = [
  {
    name: "Dashboard",
    icon: <Home />,
    link: "/dashboard",
    activeIcon: <HomeActive />,
  },
  {
    name: "Electricity",
    icon: <Electric />,
    link: "/electricity",
    activeIcon: <ElectricActive />,
  },
  // {
  //   name: "Notification",
  //   icon: <Noti />,
  //   link: "/notification",
  //   activeIcon: <NotiActive />,
  // },
  {
    name: "Transactions",
    icon: <Transaction />,
    link: "/transactions",
    activeIcon: <TransactionActive />,
  },
  {
    name: "Profile",
    icon: <Profile />,
    link: "/profile",
    activeIcon: <Profile className={styles.active} />,
  },
  {
    name: "Contact Us",
    icon: <ContactUs />,
    link: "/#contact_us",
    activeIcon: <ContactUsActive />,
  },
];
