import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Input from "components/Input/Input";
import styles from "./Electricity.module.scss";
import { setElectricityState } from "app/features/electricity";
import { postRequest } from "api/api";
import { AxiosError } from "axios";
import {
  setBuyState,
  setInitiatedTranValues,
  setReorder,
  setSendRequest,
  setTransaction,
  setValidateCustomerDetails,
  setValidatedCustomer,
  setValidatedCustomerDetails,
} from "app/features/transactions";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  InitiateTransValueType,
  SendRequestType,
  TransactionType,
  ValidateCustomerType,
} from "types/transaction";
import Modal from "components/Modal/Modal";
import StatusModal from "components/Modal/StatusModal";
import MyLoader from "components/Loader/Loader";

function RequestElectricity() {
  const dispatch = useAppDispatch();
  const {
    initiatedTransValues,
    validateCustomerDetails,
    initiatedTransaction,
    customerDetails,
    sendRequest,
    reorder,
  } = useAppSelector((state) => state.transactions);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleSendRequest = async (email: string) => {
    setLoading(true);
    try {
      const { data, status } = await postRequest(`bill/request/${email}`, {
        billerCode: validateCustomerDetails.billerCode,
        amount: reorder
          ? initiatedTransValues.totalAmount + 100
          : initiatedTransValues.totalAmount,
        reference: initiatedTransaction.referenceNumber,
        itemCode: customerDetails.itemCode,
        email: initiatedTransValues.email,
        meterNumber: customerDetails.meterNumber,
        customerName: customerDetails.name,
        customerAddress: customerDetails.address,
        phoneNumber: initiatedTransValues.phoneNumber,
        meterType: initiatedTransValues.meterType,
        distributor: initiatedTransValues.distributor,
      });
      if (status === 200) {
        toast.success(
          "We have successfully sent your request. Thank you for choosing Pawa360!"
        );
        dispatch(setBuyState("buy"));
        dispatch(setElectricityState("buy"));
        dispatch(setSendRequest({} as SendRequestType));
      }
    } catch (err) {
      const error = err as AxiosError;
      // console.log("send Request", error);
      toast.error(
        "We are unable to dispatch your request at this time. Please, try again in a few minutes"
      );
    } finally {
      setLoading(false);
      dispatch(setValidatedCustomer(false));

      dispatch(
        setInitiatedTranValues({
          meterType: "",
          distributor: "",
          totalAmount: 0,
          meterNumber: "",
          email: "",
          phoneNumber: "",
        } as InitiateTransValueType)
      );
      dispatch(setTransaction({} as TransactionType));
      dispatch(setReorder(false));
      dispatch(setBuyState("buy"));
      dispatch(setElectricityState("buy"));
      dispatch(setSendRequest({} as SendRequestType));
      dispatch(setValidateCustomerDetails({} as ValidateCustomerType));
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSendRequest(sendRequest.email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setSendRequest({
        ...sendRequest,
        [e.target.name]: e.target.value,
      })
    );
  };

  return (
    <Card className={classNames([styles.sendRequest, styles.padding40])}>
      <h5>Enter Details of who it be sent to</h5>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputs}>
          <Input label="Email" name="email" onChange={handleChange} required />
          <Input
            label="Phone Number"
            name="phoneNumber"
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.btnContainer}>
          <Button
            text="Back"
            color="dark"
            onClick={() => {
              dispatch(setBuyState("buy"));
              dispatch(setValidatedCustomer(false));
              dispatch(setElectricityState("buy"));
              dispatch(
                setInitiatedTranValues({
                  meterType: "",
                  distributor: "",
                  totalAmount: 0,
                  meterNumber: "",
                  email: "",
                  phoneNumber: "",
                } as InitiateTransValueType)
              );
              dispatch(setValidateCustomerDetails({} as ValidateCustomerType));
            }}
            disabled={loading}
          />
          <Button text="Send" color="dark" type="submit" disabled={loading} />
        </div>
      </form>
      {loading && (
        <Modal onClose={() => setShow(false)}>
          <div className={styles.myLoader}>
            <MyLoader />
          </div>{" "}
        </Modal>
      )}{" "}
      {show && <StatusModal success text="Request sent successfully" />}
    </Card>
  );
}

export default RequestElectricity;
