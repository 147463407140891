import Layout from "layout/Layout";
import styles from "./index.module.scss";
import classNames from "classnames";
import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout page="coming soon">
      <div className={classNames([styles.policy, styles.terms])}>
        <h2>Pawa360 Terms of Use</h2>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Pawa360, a product offering of DTR Technology Limited
          (“Company”, “we”, “our”, “us”)!
          <br /> <br />
          These Terms of Use (“Terms”, “Terms of Use”) govern your use of our
          service located at www.pawa360.africa (“Pawa360”, “Service”,
          “Application”, “Platform”) operated by DTR Technology Limited. Your
          agreement with us includes these Terms. You acknowledge that you have
          read and understood them, and agree to be bound by them. If you do not
          agree with (or cannot comply with) Agreements, then you may not use
          the Service. These Terms apply to all visitors, users and others who
          wish to access or use the Service.
        </p>
        <h3>2. Communications</h3>
        <p>
          By using our Service, you agree to subscribe to newsletters, marketing
          or promotional materials and other information we may send. However,
          you may opt out of receiving any, or all, of these communications from
          us by following the unsubscribe link or by emailing at{" "}
          <a href="mailto:hello@pawa360.africa">hello@pawa360.africa.</a>
        </p>
        <h3>3. Purchases</h3>
        <p>
          Suppose you wish to complete a transaction while using the service
          made available through www.pawa360.africa. In that case, you may be
          asked to supply certain information relevant to your transaction
          including but not limited to, your credit or debit card number, and
          the expiration date of your card. <br />
          You represent and warrant that: (i) you have the legal right to use
          any card(s) or other payment method(s) in connection with any
          transaction; and that (ii) the information you supply to us is true,
          correct and complete.
          <br />
          We may employ third-party services to facilitate payment and the
          completion of transactions. By submitting your information, you grant
          us the right to provide the information to these third parties subject
          to our Privacy Policy.
          <br />
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to service availability, or other
          reasons.
          <br />
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.
        </p>
        <h3>4. Promotions</h3>
        <p>
          Any promotions made available through Pawa360 may be governed by rules
          that are separate from these Terms of Use. If you participate in any
          Promotions, please review the applicable rules as well as our Privacy
          Policy. If the rules for a Promotion conflict with these Terms of
          Service, these will be communicated and will apply.
        </p>
        <h3>5. Refund Policy</h3>
        <p>
          Refunds will be issued only in cases where the value of the
          transaction has not resulted in a consumption of value (e.g.
          electricity token). Please note that validation of non-consumption
          will be checked before the payment is refunded. Refunds will be
          processed no later than 7 days from the day on which the refund
          request is received. The same means of payment as You used for the
          transaction will be used, and You will not incur any fees for such
          reimbursement.
        </p>
        <h3>6. Content</h3>
        <p>
          Content found on or through this platform is the property of DTR
          Technology Limited or used with permission. You may not distribute,
          modify, transmit, reuse, download, repost, copy, or use said Content,
          whether in whole or in part, for commercial purposes or personal gain,
          without express advance written permission from us.
        </p>
        <h3>7. Prohibited Uses</h3>
        <p>
          You may use the Service only for lawful purposes and in accordance
          with the Terms. You agree not to use the service:
          <br /> <br />
          0.1. In any way that violates any applicable national or international
          law or regulation. <br />
          0.2. For the purpose of exploiting, harming, or attempting to exploit
          or harm minors in any way by exposing them to inappropriate content or
          otherwise. <br />
          0.3. To transmit, or procure the sending of, any advertising or
          promotional material, including any “junk mail”, “chain letter,”
          “spam,” or any other similar solicitation. <br />
          0.4. To impersonate or attempt to impersonate a Company, a Company
          employee, another user, or any other person or entity. <br />
          0.5. In any way that infringes upon the rights of others, or in any
          way is illegal, threatening, fraudulent, or harmful, or in connection
          with any unlawful, illegal, fraudulent, or harmful purpose or
          activity. <br />
          0.6. To engage in any other conduct that restricts or inhibits
          anyone’s use or enjoyment of Service, or which, as determined by us,
          may harm or offend the Company or users of Service or expose them to
          liability. <br /> <br />
          Additionally, you agree not to: <br /> <br />
          0.1. Use the Service in any manner that could disable, overburden,
          damage, or impair the Service or interfere with any other party’s use
          of the Service, including their ability to engage in real-time
          activities through the Service. <br />
          0.2. Use any robot, spider, or other automatic device, process, or
          means to access Service for any purpose, including monitoring or
          copying any of the material on the Service. <br />
          0.3. Use any manual process to monitor or copy any of the material on
          the Service or for any other unauthorized purpose without our prior
          written consent. <br />
          0.4. Use any device, software, or routine that interferes with the
          proper working of the Service. <br />
          0.5. Introduce any viruses, trojan horses, worms, logic bombs, or
          other material which is malicious or technologically harmful. <br />
          0.6. Attempt to gain unauthorized access to, interfere with, damage,
          or disrupt any parts of the Service, the server on which the Service
          is stored, or any server, computer, or database connected to the
          Service. <br />
          0.7. Attack the Service via a denial-of-service attack or a
          distributed denial-of-service attack. <br />
          0.8. Take any action that may damage or falsify the Company's rating.{" "}
          <br />
          0.9. Otherwise attempt to interfere with the proper working of the
          Service.
        </p>
        <h3>8. Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </p>
        <h3>9. Accounts</h3>
        <p>
          When you create an account with us, you guarantee that the information
          you provide us is accurate, complete, and current at all times.
          Inaccurate, incomplete, or obsolete information may result in the
          immediate termination of your account on the service. <br />
          You are responsible for maintaining the confidentiality of your
          account, including but not limited to the restriction of access to
          your computer and/or account. You agree to accept responsibility for
          any activities or actions that occur under your account, whether your
          password is with our Service or a third-party service. You must notify
          us immediately upon becoming aware of any breach of security or
          unauthorized use of your account. <br />
          You may not use the name of another person or entity or that is not
          lawfully available for use, a name or trademark that is subject to any
          rights of another person or entity other than you, without appropriate
          authorization. You may not use as a username any name that is
          offensive, vulgar or obscene. <br />
          We reserve the right to refuse the service, terminate accounts, remove
          or edit content, or cancel transactions at our sole discretion.
        </p>
        <h3>10. Intellectual Property</h3>
        <p>
          Pawa360 and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of DTR Technology Limited and its licensors and may not be
          used in connection with any product or service without the prior
          written consent of DTR Technology Limited.
        </p>
        <h3>11. Error Reporting and Feedback</h3>
        <p>
          You may provide us either directly at{" "}
          <a href="mailto:hello@pawa360.africa">hello@pawa360.africa.</a> or via
          third-party sites and tools with information and feedback concerning
          errors, suggestions for improvements, ideas, problems, complaints, and
          other matters related to our Service (“Feedback”). You acknowledge and
          agree that: (i) you shall not retain, acquire or assert any
          intellectual property right or other right, title or interest in or to
          the Feedback; (ii) the Company may have development ideas similar to
          the Feedback; (iii) Feedback does not contain confidential information
          or proprietary information from you or any third party; and (iv) the
          Company is not under any obligation of confidentiality concerning the
          Feedback. In the event the transfer of the ownership to the Feedback
          is not possible due to applicable mandatory laws, you grant the
          Company and its affiliates an exclusive, transferable, irrevocable,
          free-of-charge, sub-licensable, unlimited and perpetual right to use
          (including copy, modify, create derivative works, publish, distribute
          and commercialise) Feedback in any manner and for any purpose.
        </p>
        <h3>12. Links To Other Websites</h3>
        <p>
          Our Service may contain links to third-party websites or services that
          are not owned or controlled by DTR Technology Limited. DTR Technology
          Limited has no control over and assumes no responsibility for the
          content, privacy policies, or practices of any third-party websites or
          services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </p>
        <h3>13. Disclaimer Of Warranty</h3>
        <p>
          These services are provided by DTR Technology Limited on an “as is”
          and “as available” basis. The company makes no representations or
          warranties of any kind, express or implied, as to the operation of
          their services, or the information, content or materials included
          therein. You expressly agree that your use of the service, the
          content, and any services or items obtained from us is at your sole
          risk. <br />
          Neither the company nor any person associated with the company makes
          any warranty or representation with respect to the completeness,
          security, reliability, quality, accuracy, or availability of the
          services. <br />
          Without limiting the foregoing, neither the company nor anyone
          associated with the company represents or warrants that the services,
          their content, or any services or items obtained through the services
          will be accurate, reliable, error-free, or uninterrupted, that defects
          will be corrected, that the services or the server that makes it
          available are free of viruses or other harmful components or that the
          services or any services or items obtained through the services will
          otherwise meet your needs or expectations. company hereby disclaims
          all warranties of any kind, whether express or implied, statutory, or
          otherwise, including but not limited to any warranties of
          merchantability, non-infringement, and fitness for a particular
          purpose. <br />
          The foregoing does not affect any warranties which cannot be excluded
          or limited under applicable law.
        </p>
        <h3>14. Limitation Of Liability</h3>
        <p>
          Neither DTR Technology Limited nor Pawa360 will be held responsible
          for improper or illegal use of the service. Pawa360, its directors,
          employees, or agents will not be liable for any loss or damage to you
          or a third party when using the service except where it has been
          determined under applicable law.
          <br />
          Pawa360 will only be liable for damages where it has been determined
          that Pawa360 is solely responsible.
          <br />
          Where it relates to the use of a third-party service via Pawa360,
          Pawa360 will not be held liable if you do not adhere to the Terms of
          use of the third party.
        </p>
        <h3>15. Termination</h3>
        <p>
          We may terminate or suspend your account and bar access to Pawa360
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of Terms. <br /> If you wish to
          terminate your account, you may simply discontinue using the Service.
          <br />
          All provisions of Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h3>16. Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of Nigeria, which governing law applies to the agreement without
          regard to its conflict of law provisions. <br />
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have had between
          us regarding Service.
        </p>
        <h3>17. Changes To Service</h3>
        <p>
          We reserve the right to withdraw or amend our Service, and any service
          or material we provide via Service, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of the
          Service is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of the Service, or the
          entire Service, to users, including registered users.
        </p>
        <h3>18. Acknowledgement</h3>
        <p>
          By using the service or other services provided by us, you acknowledge
          that you have read these Terms of Use and agree to be bound by them.
        </p>
        <h3>19. Changes to Terms of Use</h3>
        <p>
          We may update Our Terms of Use from time to time. We will notify You
          of any changes by posting the new Terms of Use on this page. Your
          continued use of the service following the posting of the revised
          Terms of Use means that you accept and agree to the changes. You are
          advised to review the Terms of Use periodically for any changes.
          Changes to the Terms of Use are effective when they are posted on this
          page.
        </p>
        <h3>20. Contact Us</h3>
        <p>
          If you have any questions about the Terms of Use, You can contact us:
        </p>
        <ul>
          <li>By email: hello@pawa360.africa</li>
          <li>
            By visiting this page on our website{" "}
            <a
              href="https://www.pawa360.africa"
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>{" "}
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Terms;
