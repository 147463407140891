import {
  setInitiatedTranValues,
  setLoadToken,
  setRequestedDetails,
  setValidateCustomerDetails,
  setValidatedCustomer,
  setValidatedCustomerDetails,
} from "app/features/transactions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { TransactionContext } from "context/TransactionContext";
import Layout from "layout/Layout";
import { useContext, useEffect } from "react";
import { formatCurrency } from "utils/formatter";
import styles from "./index.module.scss";
import { ReactComponent as Logo } from "assets/icons/logoLarge.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InitiateTransValueType, RequestedDetails } from "types/transaction";
import { handleButtonClick } from "analytics";
import Modal from "components/Modal/Modal";
import MyLoader from "components/Loader/Loader";

const RequestedElectricity = () => {
  const search = useLocation().search;
  const billerCode = new URLSearchParams(search).get("billerCode");
  const amount = new URLSearchParams(search).get("amount");
  const itemCode = new URLSearchParams(search).get("itemCode");
  const email = new URLSearchParams(search).get("email");
  const phoneNumber = new URLSearchParams(search).get("phoneNumber");
  const distributor = new URLSearchParams(search).get("distributor");
  const meterNumber = new URLSearchParams(search).get("meterNumber");
  const meterType = new URLSearchParams(search).get("meterType");
  const customerName = new URLSearchParams(search).get("customerName");
  const customerAddress = new URLSearchParams(search).get("customerAddress");
  const requester = new URLSearchParams(search).get("requester");

  const {
    handleInitiateTransaction,
    calculateElecCost,
    calculateFlutterCharge,
    calculateVat,
  } = useContext(TransactionContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    initiatedTransValues,
    customerDetails,
    buyButtonLoading,
    loadToken,
    validateCustomerDetails,
    showTransReceipt,
  } = useAppSelector((state) => state.transactions);

  useEffect(() => {
    if (showTransReceipt) {
      navigate("/");
      dispatch(setRequestedDetails({} as RequestedDetails));
    }
  }, [showTransReceipt, navigate]);

  useEffect(() => {
    dispatch(setValidatedCustomer(true));
    if (search) {
      dispatch(
        setRequestedDetails({
          totalAmount: parseInt(amount!),
          email: email!,
          meterNumber: meterNumber!,
          meterType: meterType!,
          distributor: distributor!,
          phoneNumber: phoneNumber!,
          name: customerName,
          itemCode: itemCode,
          address: customerAddress,
          billerCode: billerCode!,
        })
      );
      dispatch(
        setInitiatedTranValues({
          ...initiatedTransValues,
          totalAmount: parseInt(amount!),
          email: email!,
          meterNumber: meterNumber!,
          meterType: meterType!,
          distributor: distributor!,
          phoneNumber: phoneNumber!,
          serviceChargeAmount: 100,
          providerChargeAmount: calculateFlutterCharge(parseInt(amount!) + 100),
          billPurchaseAmount: calculateElecCost(parseInt(amount!) + 100),
          vatChargeAmount: calculateVat(parseInt(amount!) + 100),
        })
      );
      dispatch(
        setValidateCustomerDetails({
          ...validateCustomerDetails,
          billerCode: billerCode!,
          itemCode: itemCode,
          meterNumber: meterNumber,
        })
      );
      dispatch(
        setValidatedCustomerDetails({
          ...customerDetails,
          name: customerName,
          address: customerAddress,
          meterNumber: meterNumber!,
          email: email,
          itemCode: itemCode!,
        })
      );
    }
  }, [search]);

  return (
    <>
      <Layout footerStyle={styles.footer}>
        <div className={styles.wrapper}>
          <div className={styles.leftContainer}>
            <Card
              className={classNames([styles.userDetails, styles.padding40])}
            >
              <h5>
                {requester
                  ? `Request from ${requester} `
                  : "Electricity Request"}
              </h5>
              <div className={styles.container}>
                <div className={styles.details}>
                  <div className={styles.item}>
                    <p className={styles.title}>Name</p>
                    <p className={styles.text}>{customerDetails.name}</p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Address</p>
                    <p className={styles.text}>{customerDetails.address}</p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Meter Number</p>
                    <p className={styles.text}>{customerDetails.meterNumber}</p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Email</p>
                    <p className={styles.text}>{initiatedTransValues.email}</p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Meter Type</p>
                    <p className={styles.text}>
                      {initiatedTransValues.meterType}
                    </p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Phone Number</p>
                    <p className={styles.text}>
                      {initiatedTransValues.phoneNumber}
                    </p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>Disco</p>
                    <p className={styles.text}>
                      {initiatedTransValues.distributor}
                    </p>
                  </div>
                  <div className={styles.item}>
                    <p className={styles.title}>
                      Amount (**service charge of NGN 100)
                    </p>
                    <p className={styles.text}>
                      NGN{" "}
                      {initiatedTransValues?.totalAmount &&
                        formatCurrency(
                          (initiatedTransValues?.totalAmount).toString()
                        )}
                    </p>
                  </div>
                </div>
                <div className={classNames([styles.btnContainer])}>
                  <Button
                    text="Decline"
                    color="dark"
                    onClick={() => {
                      dispatch(
                        setInitiatedTranValues({
                          meterType: "",
                          distributor: "",
                          totalAmount: 0,
                          meterNumber: "",
                          email: "",
                          phoneNumber: "",
                        } as InitiateTransValueType)
                      );
                      navigate("/");
                      dispatch(setValidatedCustomer(false));
                      handleButtonClick("Declined Electricity Request", "Decline");
                    }}
                    disabled={buyButtonLoading}
                  />
                  <Button
                    text="Accept"
                    color="dark"
                    disabled={buyButtonLoading}
                    onClick={() => {
                      handleInitiateTransaction(initiatedTransValues);
                      handleButtonClick("Accepted Electricity Request", "Accept");
                    }}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.right}>
            <Logo className={styles.logoBig} />
          </div>
        </div>
      </Layout>
      {loadToken && (
        <Modal
          onClose={() => {
            dispatch(setLoadToken(false));
          }}
        >
          <div className={styles.myLoader}>
            <MyLoader />
          </div>
        </Modal>
      )}
    </>
  );
};

export default RequestedElectricity;
