import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

interface DashboardType {
  navState: string;
}

const initialState: DashboardType = {
  navState: "Dashboard",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setNavState: (state, action: PayloadAction<string>) => {
      state.navState = action.payload;
    },
  },
});

export const { setNavState } = dashboardSlice.actions;
export const selectCount = (state: RootState) => state.dashboard

export default dashboardSlice.reducer;
