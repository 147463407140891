import DashboardLayout from "layout/Dashboard/DashboardLayout";
import React from "react";

function DashNotification() {
  return (
    <DashboardLayout
      breadcrumb="Notification"
      breadcrumbLink="notification"
      title="Notification"
    >
      Dash
    </DashboardLayout>
  );
}

export default DashNotification;
