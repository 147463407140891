import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ElectricityStateType } from "types/electricity";

interface ElectricityType {
  electricityState: ElectricityStateType;
}

const initialState: ElectricityType = {
  electricityState: "buy",
};

export const electricitySlice = createSlice({
  name: "electricity",
  initialState: initialState,
  reducers: {
    setElectricityState: (state, action: PayloadAction<ElectricityStateType>) => {
      state.electricityState = action.payload;
    },
  },
});

export const { setElectricityState } = electricitySlice.actions;
export const selectCount = (state: RootState) => state.electricity;

export default electricitySlice.reducer;
