import styles from "./Nav.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import logoDark from "assets/icons/logoDark.svg";
import { ReactComponent as Menu } from "assets/icons/menu-icon.svg";
import { useState } from "react";
import MobileNav from "./MobileNav";
import { handleButtonClick } from "analytics";

type Props = { page?: string };

function NavBar({ page }: Props) {
  const [shownav, setShowNav] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.navBar}>
        <Link
          to={"/"}
          onClick={() => handleButtonClick("Navigated to Home page")}
        >
          <Logo className={styles.logoLight} />
          <img src={logoDark} alt="pawa360" className={styles.logoDark} />
        </Link>
        <ul className={styles.hide}>
          <li>How We Work</li>
          <li>About Us</li>
          <li>Contact Us</li>
        </ul>
        <ul className={page === "coming soon" ? styles.hide : ""}>
          <li>
            <Link
              to="/login"
              onClick={() => handleButtonClick("Navigated to Login page")}
            >
              {" "}
              Login
            </Link>
          </li>
          <li>
            <Button
              text="sign up"
              color="primary"
              onClick={() => {
                navigate("/signup");
                handleButtonClick("Navigated to Signup page");
              }}
            />
          </li>
        </ul>
        <div className={page === "coming soon" ? styles.hide : styles.menu}>
          <Menu onClick={() => setShowNav(true)} />
        </div>
      </div>
      {shownav && <MobileNav close={setShowNav} />}
    </>
  );
}

export default NavBar;
