import Button from "components/Button/Button";
import styles from "../index.module.scss";
import Input from "components/Input/Input";
import OtherSignin from "components/OtherSignin/OtherSignin";
import { useState } from "react";
import Auth from "../Auth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { postRequest } from "api/api";
import { setUser } from "app/features/auth";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { handleButtonClick } from "analytics";

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [emailOrPhone, setEmailOrPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handleLogin = async (value: string) => {
    setLoading(true);
    try {
      const { data, status } = await postRequest("user/login", {
        emailOrPhone: value,
      });
      if (status === 200) {
        dispatch(setUser(value));

        navigate("/verify");
        toast.success("Please check your email for your OTP");
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.status === 404) {
        toast.error(
          "We are unable to log you in because you have not signed up. Please go to the Signup page and sign up with a valid email address. Thank you."
        );
      }
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth>
      <div className={styles.leftContainer}>
        <div className={styles.left}>
          <h1 className={styles.leftTitle}>Welcome Back 👋</h1>
          <form
            className={styles.leftContent}
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin(emailOrPhone);
              handleButtonClick("Log in Button Clicked", "Log in");
            }}
          >
            <p className={styles.leftText}>
              Today is a new day. It's your day. You shape it. Log in to buy
              electricity and manage your account.
            </p>
            <Input
              label="Email"
              value={emailOrPhone}
              name="emailOrPhone"
              onChange={(e) => {
                setEmailOrPhone(e.target.value);
              }}
              required
            />
            {/* <div className={styles.forgot}>
            <Link to="/">Forgot Email?</Link>
          </div> */}
            <Button
              text="Log in"
              color="secondary"
              size="large"
              className={styles.submitBtn}
              type="submit"
              disabled={loading}
            />
            <OtherSignin />
            <div className={styles.account}>
              <h6>Don't you have an account?</h6>
              <p
                onClick={() => {
                  navigate("/signup");
                  handleButtonClick("Navigated to Sign up page", "Sign up");
                }}
              >
                {" "}
                Sign up
              </p>
            </div>
          </form>
        </div>
        <p className={styles.copyright}>© 2024 ALL RIGHTS RESERVED</p>
      </div>
    </Auth>
  );
}

export default Login;
