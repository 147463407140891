import { setElectricityState } from "app/features/electricity";
import { setReorder, setTransaction } from "app/features/transactions";
import { useAppDispatch } from "app/hooks";
import Button from "components/Button/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TransactionType } from "types/transaction";
import { formatCurrency, formatDate } from "utils/formatter";
import styles from "./TransactionItem.module.scss";

interface PropType {
  data: TransactionType;
  onclick?: () => void;
}

const TransactionItem = ({ data, onclick }: PropType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (e.target.id === "reorder") {
      dispatch(setElectricityState("buy"));
      dispatch(setTransaction(data));
      dispatch(setReorder(true));
      navigate("/electricity");
    }
  };

  return (
    <div className={styles.transItem} onClick={onclick}>
      <img src="" alt="" />
      <div className={styles.itemCenter}>
        <p>{data.distributor}</p>
        <p className={styles.smallText}>{data.transactionStatus}</p>
      </div>
      <div className={styles.amount}>
        <p
          className={
            data.transactionStatus === "Success"
              ? styles.successAmount
              : styles.failedAmount
          }
        >
          {formatCurrency(data?.totalAmount.toString())}
        </p>
        <p className={styles.smallText}>
          {formatDate({ val: data?.dateCreated })}
        </p>
      </div>
      {/* <Button
        text="Reorder"
        id="reorder"
        color="tertiary"
        onClick={handleClick}
        size="small"
        className={styles.reorderBtn}
      /> */}
    </div>
  );
};

export default TransactionItem;
