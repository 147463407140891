import { setElectricityState } from "app/features/electricity";
import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Input from "components/Input/Input";
import styles from "./Electricity.module.scss";
import MoneyInput from "components/Input/MoneyInput";
import { useContext, useEffect, useState } from "react";
import SelectForm from "components/Select/Select";
import { TransactionContext } from "context/TransactionContext";
import {
  setBuyState,
  setInitiatedTranValues,
  setShowTransReceipt,
  setTransaction,
  setValidateCustomerDetails,
  setValidatingCustomer,
} from "app/features/transactions";
import MyLoader from "components/Loader/Loader";
import Receipt from "components/Receipt/Receipt";
import Modal from "components/Modal/Modal";
import { InitiateTransValueType, TransactionType } from "types/transaction";
import { handleButtonClick, trackMixpanelEvent } from "analytics";

function BuyElectricity() {
  const dispatch = useAppDispatch();
  const {
    billers,
    validatedCustomer,
    validatingCustomer,
    validateCustomerDetails,
    transaction,
    initiatedTransValues,
    loadToken,
    showTransReceipt,
    billPaymentDetails,
    buyState,
    reorder,
  } = useAppSelector((state) => state.transactions);
  const {
    validateCustomer,
    validateFields,
    error,
    calculateElecCost,
    calculateFlutterCharge,
    calculateVat,
  } = useContext(TransactionContext);
  const [amount, setAmount] = useState(
    transaction
      ? transaction.totalAmount - 100
      : initiatedTransValues
      ? initiatedTransValues.totalAmount - 100
      : 0
  );
  const [distrib, setDistrib] = useState(
    transaction
      ? transaction.distributor
      : validateCustomerDetails.billerCode || ""
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInitiatedTranValues({
        ...initiatedTransValues,
        [e.target.name]: e.target.value,
      })
    );
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // e.preventDefault();
    const targetButton = (e.nativeEvent as any).submitter as HTMLButtonElement;
    if (targetButton.id === "check") {
      dispatch(setBuyState("check"));
      handleButtonClick("Navigated to Confirm Details page", "Proceed");
    } else {
      dispatch(setBuyState("request"));
      handleButtonClick("Navigated to Send Request page", "Send Request");
    }
    validateCustomer(validateCustomerDetails);
  };

  useEffect(() => {
    if (validatedCustomer) {
      if (!reorder) {
        dispatch(
          setInitiatedTranValues({
            ...initiatedTransValues,
            totalAmount: amount + 100,
            serviceChargeAmount: 100,
            providerChargeAmount: calculateFlutterCharge(amount + 100),
            billPurchaseAmount: calculateElecCost(amount + 100),
            vatChargeAmount: calculateVat(amount + 100),
          })
        );
      }
      if (buyState === "check") {
        dispatch(setElectricityState("checkDetails"));
      } else if (buyState === "request") {
        dispatch(setElectricityState("request"));
      } else {
        dispatch(setElectricityState("buy"));
      }
    }
  }, [buyState, validatedCustomer]);

  useEffect(() => {
    if (transaction) {
      const biller = billers.find(
        (item) => item.label === transaction.distributor
      )?.value;
      dispatch(
        setInitiatedTranValues({
          meterNumber: transaction.meterNumber,
          meterType: transaction.meterType,
          email: transaction.email,
          phoneNumber: transaction.phoneNumber,
          distributor: transaction.distributor,
          totalAmount: transaction.totalAmount - 100,
          serviceChargeAmount: 100,
          providerChargeAmount: calculateFlutterCharge(transaction.totalAmount),
          billPurchaseAmount: calculateElecCost(transaction.totalAmount),
          vatChargeAmount: calculateVat(transaction.totalAmount),
        })
      );
      if (biller)
        dispatch(
          setValidateCustomerDetails({
            ...validateCustomerDetails,
            meterNumber: transaction.meterNumber,
            billerCode: biller,
          })
        );
    }
  }, [reorder, transaction]);

  return (
    <Card className={styles.padding40}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (
            validateFields({
              ...initiatedTransValues,
              meterType: initiatedTransValues.meterType,
              distributor: initiatedTransValues.distributor,
              totalAmount: initiatedTransValues.totalAmount,
            })
          ) {
            trackMixpanelEvent("Electricity Details", {
              amount: initiatedTransValues.totalAmount,
              email: initiatedTransValues.email,
              phone: initiatedTransValues.phoneNumber,
              meterNumber: initiatedTransValues.meterNumber,
              meterTypr: initiatedTransValues.meterType,
              distributor: initiatedTransValues.distributor!,
            });
            handleSubmit(e);
          }
        }}
      >
        <div className={styles.grid3}>
          <Input
            label="Meter Number"
            name="meterNumber"
            value={initiatedTransValues.meterNumber}
            onChange={(e) => {
              dispatch(
                setValidateCustomerDetails({
                  ...validateCustomerDetails,
                  meterNumber: e.target.value,
                })
              );
              handleChange(e);
            }}
            loading={validatingCustomer}
            validated={validatedCustomer}
            validate
            required
          />
          <SelectForm
            id=""
            options={[
              { value: "PREPAID", label: "Prepaid" },
              { value: "POSTPAID", label: "Postpaid" },
            ]}
            label="Meter Type"
            name="meterType"
            onChange={(e) => {
              dispatch(
                setInitiatedTranValues({
                  ...initiatedTransValues,
                  meterType: e.target.value,
                })
              );
            }}
            selected={initiatedTransValues.meterType}
            value={initiatedTransValues.meterType}
            errorText={error.meterType}
          />
          <SelectForm
            id=""
            options={billers}
            label="Disco"
            name="Distributor"
            onChange={(e) => {
              const disco = billers.find(
                (item) => item.value === e.target.value
              );
              setDistrib(e.target.value);
              if (disco) {
                dispatch(
                  setInitiatedTranValues({
                    ...initiatedTransValues,
                    distributor: disco.label,
                  })
                );
              }
              dispatch(
                setValidateCustomerDetails({
                  ...validateCustomerDetails,
                  billerCode: e.target.value,
                })
              );
            }}
            selected={initiatedTransValues.distributor}
            // value={distrib}
            errorText={error.distributor}
          />
          <MoneyInput
            label="Amount"
            name="amount"
            onChange={(e) => {
              setAmount(parseInt(e));
              dispatch(
                setInitiatedTranValues({
                  ...initiatedTransValues,
                  totalAmount: parseInt(e),
                })
              );
            }}
            value={amount > 0 ? amount : 0}
            required
            error={error.totalAmount}
          />
          <Input
            label="Email"
            name="email"
            value={initiatedTransValues.email}
            onChange={handleChange}
            required
          />
          <Input
            label="Phone Number"
            name="phoneNumber"
            value={initiatedTransValues.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classNames([styles.btnContainer, styles.justifyEnd])}>
          <Button
            text="Send as Request"
            color="dark"
            disabled={validatingCustomer}
            type="submit"
            id="request"
          />
          <Button
            text="Proceed"
            color="dark"
            type="submit"
            disabled={validatingCustomer}
            id="check"
          />
        </div>
      </form>
      {validatingCustomer && (
        <Modal
          onClose={() => {
            dispatch(setValidatingCustomer(false));
          }}
        >
          <div className={styles.myLoader}>
            <MyLoader />
          </div>
        </Modal>
      )}
      {showTransReceipt && (
        <Modal
          onClose={() => {
            dispatch(setShowTransReceipt(false));
            dispatch(
              setInitiatedTranValues({
                meterType: "",
                distributor: "",
                totalAmount: 0,
                meterNumber: "",
                email: "",
                phoneNumber: "",
              } as InitiateTransValueType)
            );
            setAmount(0);
            dispatch(setTransaction({} as TransactionType));
          }}
        >
          {loadToken ? (
            <div className={styles.myLoader}>
              <MyLoader />
            </div>
          ) : (
            <Receipt transaction={billPaymentDetails} />
          )}
        </Modal>
      )}
    </Card>
  );
}

export default BuyElectricity;
