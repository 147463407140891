import Card from "components/Card/Card";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import styles from "./DashHome.module.scss";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import barChart from "assets/icons/bar_chart.svg";
import calender from "assets/icons/calendar_today.svg";
import Table from "components/Table/Table";
import TableItem from "components/TableItem/TableItem";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { TransactionContext } from "context/TransactionContext";
import { AuthContext } from "context/AuthContext";
import { formatCurrency } from "utils/formatter";
import {
  setTransactionAmounts,
  setTransactionMonths,
} from "app/features/transactions";
import { ProfileContext } from "context/ProfileContext";
import MyLoader from "components/Loader/Loader";
import TransactionItem from "components/TransactionItem/TransactionItem";
import Button from "components/Button/Button";

type chartProp =
  | "line"
  | "pie"
  | "area"
  | "bar"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "boxPlot"
  | "radar"
  | "polarArea"
  | "rangeBar"
  | "rangeArea"
  | "treemap"
  | undefined;

function DashboardHome() {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(AuthContext);
  const [chartType, setChartType] = useState<chartProp>("line");
  const {
    transactions,
    transHistory,
    transLoading,
    transactionAmounts,
    transactionMonths,
  } = useAppSelector((state) => state.transactions);
  const { fetchTransactions, fetchTransHistory } =
    useContext(TransactionContext);
  const { fetchUserProfile } = useContext(ProfileContext);
  const dispatch = useAppDispatch();

  const formatChartHistory = () => {
    const amount: number[] = [];
    const months: string[] = [];
    if (transHistory.momValue) {
      transHistory?.momValue.forEach((item) => {
        amount.push(item?.totalAmount);
        months.push(item?.month);
      });
      dispatch(setTransactionAmounts(amount));
      dispatch(setTransactionMonths(months));
    }
  };

  function doneTransactions() {
    const totalTransactions =
      transHistory?.analysisResponse?.successfultransactions +
      transHistory?.analysisResponse?.failedtransactions;
    const res =
      (transHistory?.analysisResponse?.successfultransactions /
        totalTransactions) *
      100;

    if (res > 0) return Math.ceil(res);
    return 0;
  }

  function failedTransactions() {
    const totalTransactions =
      transHistory?.analysisResponse?.successfultransactions +
      transHistory?.analysisResponse?.failedtransactions;
    const res =
      (transHistory?.analysisResponse?.failedtransactions / totalTransactions) *
      100;

    if (res > 0) return Math.ceil(res);
    return 0;
  }

  // const toggleChart = () => {
  //   if (chartType === "line") {
  //     setChartType("bar");
  //   } else {
  //     setChartType("line");
  //   }
  // };
  const options: ApexOptions = {
    colors: ["#52D5BA", "#FF4267"],
    series: [
      {
        name: "Amount",
        data: transHistory ? transactionAmounts : [],
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Transaction History",
      align: "left",
    },
    // legend: {
    //   tooltipHoverFormatter: function(val, opts) {
    //     return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
    //   }
    // },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: transHistory ? transactionMonths : [],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    legend: {
      show: false,
    },
    // tooltip: {
    //   y: [
    //     {
    //       title: {
    //         formatter: function (val) {
    //           return val + " (mins)"
    //         }
    //       }
    //     },
    //     {
    //       title: {
    //         formatter: function (val) {
    //           return val + " per session"
    //         }
    //       }
    //     },
    //     {
    //       title: {
    //         formatter: function (val) {
    //           return val;
    //         }
    //       }
    //     }
    //   ]
    // },
    grid: {
      borderColor: "transparent",
    },
  };

  // const pieOptions: ApexOptions = {
  //   labels: ["Done", "Failed"],
  //   colors: ["#52D5BA", "#FF4267"],
  //   series: [doneTransactions(), failedTransactions()],
  //   chart: {
  //     width: "100%",
  //     height: "100%",
  //   },
  //   states: {
  //     hover: {
  //       filter: {
  //         type: "none",
  //       },
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   // hover: { mode: null },
  //   // plotOptions: {
  //   //   donut: {
  //   //     expandOnClick: false,
  //   //     donut: {
  //   //       labels: {
  //   //         show: false,
  //   //       },
  //   //     },
  //   //   },
  //   // },
  //   fill: {
  //     colors: ["#52D5BA", "#FF4267"],
  //   },
  //   tooltip: {
  //     enabled: true,
  //     theme: "dark",
  //   },
  // };

  useEffect(() => {
    fetchTransactions();
    fetchTransHistory();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (transHistory) {
      formatChartHistory();
    }
  }, [transHistory]);

  return (
    <DashboardLayout
      breadcrumb="Dashboard"
      breadcrumbLink="dashboard"
      title="Main Dashboard"
    >
      <div className={styles.dashboardHome}>
        <div className={styles.homeTop}>
          <Card>
            <div className={styles.totalPayments}>
              <div className={styles.totalAmount}>
                <div className={styles.calender}>
                  <img src={calender} alt="calender" />
                  <p>This month</p>
                </div>
                <h2>
                  NGN{" "}
                  {transHistory?.analysisResponse?.totalAmount === 0
                    ? "0"
                    : formatCurrency(
                        transHistory?.analysisResponse?.totalAmount.toString()
                      )}
                </h2>
                <p>Total Payments</p>
                {/* <div className={styles.onTrack}>
                  <img src={track} alt="track" />
                  <p>On track of this area</p>
                </div> */}
              </div>
              <div className={styles.paymentChart}>
                <div className={styles.barChart}>
                  <img src={barChart} alt="bar chart" />
                </div>
                <div className={styles.lineChart}>
                  <ReactApexChart
                    options={options}
                    series={options.series}
                    type={chartType}
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </Card>
          {/* <Card className={styles.pie}>
            <div className={styles.pieTitle}>
              <p className={styles.title}>Transaction Analysis</p>
              <Dropdown text="Monthly" background={false} />
            </div>
            <ReactApexChart
              options={pieOptions}
              series={pieOptions.series}
              type="pie"
              width="100%"
              height="70%"
            />
            <div className={styles.pieData}>
              <div className={styles.pieItem}>
                <div className={styles.status}>
                  <span className={styles.done}></span> <p>Transaction Done</p>
                </div>
                <h6>{doneTransactions()}%</h6>
              </div>
              <div className={styles.pieItem}>
                <div className={styles.status}>
                  <span className={styles.fail}></span>{" "}
                  <p>Transaction Failed</p>
                </div>
                <h6>{failedTransactions()}%</h6>
              </div>
            </div>
          </Card> */}
        </div>
        <div className={styles.homeBottom}>
          <Card>
            {transLoading ? (
              <MyLoader />
            ) : (
              <Table
                title="Recent Transactions"
                tableHead={["", "", "", "", ""]}
              >
                {transactions.length > 0 ? (
                  transactions.map((item) => (
                    // <Link to={`/transactions/${item.id}`} key={item.id}>
                    <TableItem
                      tableData={item}
                      key={item.id}
                      onclick={() => navigate(`/transactions/${item.id}`)}
                    />
                    // </Link>
                  ))
                ) : (
                  <p className={styles.emptyTrans}>No Recent Transactions</p>
                )}
              </Table>
            )}
          </Card>
        </div>
        <div className={styles.transactionMobile}>
          <Card>
            <div className={styles.flexRow}>
              <h5>Recent Transactions</h5>
              <Button
                text="View All"
                color="tertiary"
                size="small"
                onClick={() => navigate("/transactions")}
              />
            </div>
            {transLoading ? (
              <MyLoader />
            ) : transactions.length > 0 ? (
              transactions.map((item) => (
                <TransactionItem
                  data={item}
                  key={item.id}
                  onclick={() => navigate(`/transactions/${item.id}`)}
                />
              ))
            ) : (
              <p className={styles.emptyTrans}>No Transactions</p>
            )}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DashboardHome;
