import Back from "components/Back/Back";
import Button from "components/Button/Button";
import styles from "./TransactionDetails.module.scss";
import logoDark from "assets/icons/logoDark.svg";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { TransactionContext } from "context/TransactionContext";
import { useAppSelector } from "app/hooks";
import { formatCurrency, formatDate } from "utils/formatter";
import MyLoader from "components/Loader/Loader";

function TransactionDetails() {
  const { id } = useParams();
  const {
    fetchTransaction,
    calculateVat,
    calculateElecCost,
    calculateFlutterCharge,
  } = useContext(TransactionContext);
  const { transaction, transLoading } = useAppSelector(
    (state) => state.transactions
  );
  useEffect(() => {
    if (id) fetchTransaction(id);
  }, [id]);

  useEffect(() => {
    if (transaction?.totalAmount)
      calculateFlutterCharge(transaction.totalAmount);
  }, [transaction, calculateFlutterCharge]);

  return (
    <DashboardLayout
      breadcrumb="Transactions"
      breadcrumbLink="transactions"
      title="Transactions"
      center
      
    >
      <Back />
      {transLoading ? (
        <MyLoader />
      ) : (
        <>
          <div className={styles.transactionDetails}>
            <div className={styles.center}>
              <img src={logoDark} alt="pawa360" className={styles.logo} />
              <p>
                {transaction?.dateCreated &&
                  formatDate({ val: transaction?.dateCreated, fullDate: true })}
              </p>
            </div>
            {transaction?.token && (
              <div className={styles.token}>
                <h3>{transaction?.token}</h3>
              </div>
            )}
            {/* <div className={classNames([styles.flexRow, styles.item])}>
              <p className={styles.title}>Token Type</p>
              <p className={styles.text}>Credit</p>
            </div> */}
            <div className={classNames([styles.item])}>
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Customer Name</p>
                <p className={styles.text}>{transaction?.customerName}</p>
              </div>
              {/* <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Customer Type</p>
                <p className={styles.text}>R3</p>
              </div> */}
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Address</p>
                <p className={styles.text}>{transaction?.customerAddress}</p>
              </div>
            </div>
            <div className={classNames([styles.flexRow, styles.item])}>
              <p className={styles.title}>Meter Number</p>
              <p className={styles.text}>{transaction?.meterNumber}</p>
            </div>
            <div className={classNames([styles.item])}>
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Electricity Amount</p>
                <p className={styles.text}>
                  {transaction.currency}{" "}
                  {transaction?.totalAmount &&
                    formatCurrency(
                      calculateElecCost(transaction?.totalAmount).toString()
                    )}
                </p>
              </div>
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>VAT</p>
                <p className={styles.text}>
                  NGN{" "}
                  {transaction?.totalAmount &&
                    formatCurrency(
                      calculateVat(transaction?.totalAmount).toString()
                    )}
                </p>
              </div>
              {/* <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Processing fee</p>
                <p className={styles.text}>
                NGN{" "}
                  {transaction?.totalAmount &&
                    formatCurrency(
                      calculateFlutterCharge(transaction?.totalAmount).toString()
                    )}
                </p>
              </div> */}
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Service charge</p>
                <p className={styles.text}> NGN 100</p>
              </div>
              <div className={classNames([styles.flexRow])}>
                <p className={styles.title}>Total</p>
                <p className={styles.text}>
                  {" "}
                  NGN{" "}
                  {transaction?.totalAmount &&
                    formatCurrency(transaction?.totalAmount.toString())}
                </p>
              </div>
            </div>

            <div className={classNames([styles.flexRow])}>
              <p className={styles.title}>Operator</p>
              <p className={styles.text}>Pawa360</p>
            </div>
            <p className={styles.bottomText}>
              Thanks for lighting our passion. use our platform again, if your
              home isn't still Lighting up. You're always welcome here!
            </p>
            <div className={styles.center}>
              <img src={logoDark} alt="pawa360" className={styles.logo} />
            </div>
          </div>
          {/* <div className={styles.btnContainer}>
            <Button text="Send to Email" color="dark" disabled />
            <Button text="Download" color="dark" />
          </div> */}
        </>
      )}
    </DashboardLayout>
  );
}

export default TransactionDetails;