import { setToken, setVerifiedUser } from "app/features/auth";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Back from "components/Back/Back";
import Button from "components/Button/Button";
import OtpInput from "react-otp-input";
import { useContext, useState } from "react";
import styles from "../index.module.scss";
import { postRequest } from "api/api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "context/AuthContext";
import Auth from "../Auth";
import { handleButtonClick } from "analytics";

function Verify() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setAuthToken } = useContext(AuthContext);
  const { user } = useAppSelector((state) => state.auth);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerify = async () => {
    setLoading(true);
    try {
      const { data, status } = await postRequest("user/verify", {
        emailOrPhone: user,
        passcode: otp,
      });
      if (status === 200) {
        dispatch(setToken(data.token));
        dispatch(setVerifiedUser(data));
        localStorage.setItem("token", data.token);
        setAuthToken(data.token);
        navigate("/dashboard");
        toast.success("You have logged in successfully!");
      }
    } catch (err) {
      const error = err as AxiosError;
      toast.error("Invalid OTP. Please, check the OTP sent to you");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth>
      <div className={styles.leftContainer}>
        <div className={styles.left}>
          <div className={styles.backBtn}>
            <Back
              onClick={() => {
                window.history.go(-1);
              }}
            />
          </div>
          <h1 className={styles.leftTitle}>Enter authentication code</h1>

          <div className={styles.leftContent}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleVerify();
                handleButtonClick("Verify email Button Clicked", "Continue");
              }}
            >
              <p className={styles.leftText}>
                Enter the 7-digit code we just texted to your phone number or
                email.
              </p>
              <div>
                <label htmlFor="">Verification Code</label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={7}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={styles.otpContainer}
                  inputStyle={{
                    border: "1px solid #0146A3",
                    borderRadius: "6.53px",
                    width: "33px",
                    height: "35px",
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: "600",
                    caretColor: "blue",
                  }}
                />
              </div>
              <div className={styles.forgot}>
                <p className={styles.link}>Resend Code</p>
              </div>
              <Button
                text="Continue"
                color="secondary"
                size="large"
                className={styles.submitBtn}
                disabled={loading}
                type="submit"
              />
              <div className={styles.account}>
                <h6>Don't have an account?</h6>
                <p onClick={() => navigate("/signup")}> Sign up</p>
              </div>
            </form>
          </div>
        </div>
        <p className={styles.copyright}>© 2024 ALL RIGHTS RESERVED</p>
      </div>
    </Auth>
  );
}

export default Verify;
