import styles from "./Footer.module.scss";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import facebook from "assets/icons/facebook-black.svg";
import twitter from "assets/icons/twitter-black.svg";
// import telegram from "assets/icons/telegram.svg";
import instagram from "assets/icons/instagram.svg";
// import whatsapp from "assets/icons/whatsapp.svg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { handleButtonClick } from "analytics";

type FooterProps = {
  footerStyle?: string;
};
function Footer({ footerStyle }: FooterProps) {
  return (
    <div className={classNames([styles.footer, footerStyle])}>
      <div className={styles.footerTop}>
        <div>
          <Logo />
          <p className={styles.footerText}>
            Electricity at your fingertips -<br /> simpler, faster, better!
          </p>
        </div>
        <div className={styles.footerRight}>
          <ul className={styles.footerLinks}>
            <li>Home.</li>
            <li>How We Work.</li>
            <li>About.</li>
            <li>Contact.</li>
          </ul>
          <div className={styles.location}>
            <div>
              <h5>Contact Us</h5>
              <p>
                +234 902 724 0581
                {/* <br /> hello@logoipsum.com */}
              </p>
            </div>
            <div>
              <h5>Location</h5>
              <p>
                123, Ogudu Road
                <br /> Lagos 100242, Lagos, Nigeria
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerSocials}>
          {/* <div className={styles.footerSocial}><img src={whatsapp} alt="whatsapp" /></div> */}
          <div className={styles.footerSocial}>
            <Link
              to="https://x.com/pawa360africa"
              onClick={() => handleButtonClick("Navigated to Twitter page", "Twitter Icon")}
            >
              <img src={twitter} alt="twitter" />
            </Link>
          </div>
          {/* <div className={styles.footerSocial}><img src={telegram} alt="telegram" /></div>*/}

          <div className={styles.footerSocial}>
            <Link
              to="https://www.facebook.com/profile.php?id=61568597281217"
              onClick={() => handleButtonClick("Navigated to Facebook page", "Facebook Icon")}
            >
              <img src={facebook} alt="facebook" />
            </Link>
          </div>
          <div className={styles.footerSocial}>
            <Link
              to={"https://www.instagram.com/pawa360africa"}
              onClick={() => handleButtonClick("Navigated to Instagram page", "Instagram Icon")}
            >
              <img src={instagram} alt="instagram" />
            </Link>
          </div>
          <div className={styles.footerSocial}>
            <Link
              to={"https://www.linkedin.com/company/pawa360/"}
              onClick={() => handleButtonClick("Navigated to LinkedIn page", "LinkedIn Icon")}
            >
              <img
                src="https://res.cloudinary.com/ddrjp7e3l/image/upload/v1733159964/ipmde0ziugezquaqijeo.svg"
                alt="linkedin"
                style={{ width: "20px" }}
              />
            </Link>
          </div>
        </div>
        <div className={styles.privacyTerms}>
          <Link
            to="/terms-of-use"
            onClick={() => handleButtonClick("Navigated to Terms of use page", "Terms of Use")}
          >
            Terms of Use
          </Link>
          <Link
            to="/privacy-policy"
            onClick={() =>
              handleButtonClick("Navigated to Privacy Policy page", "Privacy Policy")
            }
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <div
        className={styles.center}
        style={{ marginTop: "50px", fontSize: "12px" }}
      >
        <p className={styles.footerCopy}>
          Pawa360 is a product offering of DTR Technology Limited. (RC8040209).
          © 2024 All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
