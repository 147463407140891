import styles from "./Input.module.scss";
import CurrencyInput from "react-currency-input-field";
import { useAppSelector } from "app/hooks";

type MoneyInputProps = {
  label?: string;
  className?: string;
  type?: "text" | "number";
  whiteInput?: boolean;
  name?: string;
  value: string | number;
  onChange: (value: any) => void | undefined;
  required?: boolean;
  error?: string;
};
function MoneyInput({
  label,
  className,
  type = "text",
  whiteInput,
  name,
  value,
  onChange,
  required,
  error,
}: MoneyInputProps) {
  return (
    <div className={styles.input}>
      <label htmlFor="">{label}</label>

      <CurrencyInput
        // prefix={value > 0 ? "₦ " : ""}
        className={whiteInput ? styles.whiteInput : ""}
        onValueChange={(value) => {
          onChange(value);
        }}
        name={name}
        value={value}
        required={required}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
}

export default MoneyInput;
