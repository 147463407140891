import MyLoader from "components/Loader/Loader";
import styles from "./Input.module.scss";
import validatedIcon from "assets/icons/track.svg";

type InputProps = {
  label?: string;
  className?: string;
  type?: "text" | "number";
  otpInput?: boolean;
  whiteInput?: boolean;
  name?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  loading?: boolean;
  required?: boolean;
  error?: string;
  validated?: boolean;
  validate?: boolean;
  validateMessage?: string;
};
function Input({
  label,
  className,
  type = "text",
  whiteInput,
  name,
  value,
  onChange,
  loading,
  required,
  error,
  validated,
  validate,
  validateMessage,
}: InputProps) {
  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.inputContainer}>
        <input
          type={type}
          className={whiteInput ? styles.whiteInput : ""}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
        />
        {validate && (
          <div className={styles.loader}>
            {validated && <img src={validatedIcon} alt="" />}
            {loading && <MyLoader />}
          </div>
        )}
      </div>
      {error && <span className={styles.errorMessage}>{error}</span>}

      {validated && validateMessage && <p>{validateMessage}</p>}
    </div>
  );
}

export default Input;
