import Dropdown from "components/Dropdown/Dropdown";
import greenStatus from "assets/icons/label.svg";
import fail from "assets/icons/failI.svg";
import nepa from "assets/icons/nepa.svg";
import styles from "./TableItem.module.scss";
import { TransactionType } from "types/transaction";
import { formatCurrency, formatDate } from "utils/formatter";
import classNames from "classnames";
import Button from "components/Button/Button";
import { useAppDispatch } from "app/hooks";
import { setReorder, setTransaction } from "app/features/transactions";
import { useNavigate } from "react-router-dom";
import { setElectricityState } from "app/features/electricity";

type TableItemProps = {
  tableData: TransactionType;
  onclick?: () => void;
};

function TableItem({ tableData, onclick }: TableItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (e.target.id === "reorder") {
      dispatch(setElectricityState('buy'))
      dispatch(setTransaction(tableData));
      dispatch(setReorder(true));
      navigate("/electricity");
    }
  };
  return (
    <tr className={styles.tableRow} onClick={onclick}>
      <td className={classNames([styles.flex, styles.disco])}>
        <img src={nepa} alt="" />
        <p className={styles.text}>{tableData.distributor}</p>
      </td>
      <td className={styles.time}>
        <p>{formatDate({ val: tableData?.dateCreated })}</p>
      </td>
      <td className={classNames([styles.flex, styles.status])}>
        {tableData.transactionStatus && (
          <img
            src={tableData.transactionStatus === "Success" ? greenStatus : fail}
            alt=""
          />
        )}{" "}
        <p className={styles[tableData.transactionStatus]}>
          {tableData.transactionStatus}
        </p>
      </td>
      <td className={styles.amount}>
        <p>
          {tableData.currency} {formatCurrency(tableData?.totalAmount.toString())}
        </p>
      </td>
      <td className={styles.reorder}>
        {/* <Dropdown text="Reorder" /> */}
        <Button
          text="Reorder"
          id="reorder"
          color="tertiary"
          onClick={handleClick}
        />
      </td>
    </tr>
  );
}

export default TableItem;
