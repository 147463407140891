import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getRequest = async (uri: string) => {
  return await axios.get(`${baseUrl}/${uri}`);
};

export const postRequest = async (uri: string, data: object) => {
  return await axios.post(`${baseUrl}/${uri}`, data);
};

export const putRequest = async (uri: string, data: object) => {
  return await axios.put(`${baseUrl}/${uri}`, data);
};

export const deleteRequest = async (uri: string, data: object) => {
  return await axios.delete(`${baseUrl}/${uri}`, data);
};
