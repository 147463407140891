import { postRequest } from "api/api";
import { setToken, setUser } from "app/features/auth";
import { useAppDispatch } from "app/hooks";
import axios, { AxiosError } from "axios";
import { createContext, useState } from "react";
import toast from "react-hot-toast";

export type authState = "login" | "register";
interface AuthContextType {
  setAuthToken: (value: string) => void;
  handleLogout: () => void;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const setAuthToken = (token: string) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };


  const handleLogout = () => {
    dispatch(setToken(""));
    localStorage.removeItem("token");
    setAuthToken("");
    window.location.pathname = "/login";
  };

  return (
    <AuthContext.Provider
      value={{
        setAuthToken,
        handleLogout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
