import styles from "./Landing.module.scss";
import logo from "assets/icons/logo.svg";
import goDown from "assets/icons/go down.svg";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import classNames from "classnames";
import nepa from "assets/icons/nepa.svg";
import aedc from "assets/icons/aedc.svg";
import jdc from "assets/icons/jdc.svg";
import kedco from "assets/icons/kedco.svg";
import ibdc from "assets/icons/ibdc.svg";
import ikedc from "assets/icons/ikedc.svg";
import MoneyInput from "components/Input/MoneyInput";
import { useContext, useEffect, useState } from "react";
import SelectForm from "components/Select/Select";
import Modal from "components/Modal/Modal";
import Receipt from "components/Receipt/Receipt";
import MyLoader from "components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { TransactionContext } from "context/TransactionContext";
import {
  setInitiatedTranValues,
  setLoadToken,
  setShowTransReceipt,
  setValidateCustomerDetails,
  setValidatedCustomer,
  setValidatingCustomer,
} from "app/features/transactions";
import { InitiateTransValueType } from "types/transaction";
import { handleButtonClick, trackMixpanelEvent } from "analytics";
import { useNavigate } from "react-router-dom";
import ConfirmElectModal from "components/Modal/ConfirmElectModal";

function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    billers,
    validatedCustomer,
    validatingCustomer,
    validateCustomerDetails,
    loadToken,
    billPaymentDetails,
    showTransReceipt,
    initiatedTransValues,
  } = useAppSelector((state) => state.transactions);
  const {
    validateCustomer,
    validateFields,
    error,
    calculateElecCost,
    calculateFlutterCharge,
    calculateVat,
  } = useContext(TransactionContext);
  const [amount, setAmount] = useState(
    initiatedTransValues?.totalAmount - 100 || 0
  );
  const [confirm, setConfirm] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setInitiatedTranValues({
        ...initiatedTransValues,
        [e.target.name]: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (validatedCustomer) {
      setConfirm(true);
      dispatch(
        setInitiatedTranValues({
          ...initiatedTransValues,
          totalAmount: amount + 100,
          serviceChargeAmount: 100,
          providerChargeAmount: calculateFlutterCharge(amount + 100),
          billPurchaseAmount: calculateElecCost(amount + 100),
          vatChargeAmount: calculateVat(amount + 100),
        })
      );
    } else {
      navigate("/");
    }
  }, [validatedCustomer]);

  return (
    <>
      <div className={styles.header}>
        <div className={classNames([styles.topHeader, styles.center])}>
          <div className={classNames([styles.flexCenter, styles.top])}>
            <div className={styles.leftHeader}>
              <h1>
                <span> Electricity</span> at your fingertips -<br /> simpler,
                faster, better!
              </h1>
              <div className={styles.tagLine}>
                <ul>
                  <li>Buy</li>
                  <li>Track</li>
                  <li>Reorder</li>
                  <li>Request</li>
                </ul>
              </div>
            </div>
            <div className={styles.rightHeader}>
              <div className={styles.center}>
                <img src={logo} alt="pawa360" className={styles.logo} />
                <p>You can buy power units easily here</p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  // console.log(initiatedTransValues);
                  if (
                    validateFields({
                      ...initiatedTransValues,
                      meterType: initiatedTransValues.meterType,
                      distributor: initiatedTransValues.distributor,
                      totalAmount: initiatedTransValues.totalAmount,
                    })
                  ) {
                    validateCustomer(validateCustomerDetails);
                    handleButtonClick(
                      "Navigated to Confirm Details modal",
                      "Buy Electricity"
                    );
                    trackMixpanelEvent("Electricity Details", {
                      amount: initiatedTransValues.totalAmount,
                      email: initiatedTransValues.email,
                      phone: initiatedTransValues.phoneNumber,
                      meterNumber: initiatedTransValues.meterNumber,
                      meterTypr: initiatedTransValues.meterType,
                      distributor: initiatedTransValues.distributor!,
                    });
                  }
                }}
              >
                <div className={styles.grid2}>
                  <Input
                    label="Meter Number"
                    whiteInput
                    name="meterNumber"
                    value={initiatedTransValues.meterNumber}
                    onChange={(e) => {
                      dispatch(
                        setValidateCustomerDetails({
                          ...validateCustomerDetails,
                          meterNumber: e.target.value,
                        })
                      );
                      handleChange(e);
                    }}
                    loading={validatingCustomer}
                    validated={validatedCustomer}
                    validate
                    required
                  />
                  <SelectForm
                    id=""
                    options={[
                      { value: "PREPAID", label: "Prepaid" },
                      { value: "POSTPAID", label: "Postpaid" },
                    ]}
                    label="Meter Type"
                    name="meterType"
                    onChange={(e) => {
                      dispatch(
                        setInitiatedTranValues({
                          ...initiatedTransValues,
                          meterType: e.target.value,
                        })
                      );
                    }}
                    whiteInput
                    errorText={error.meterType}
                    selected={initiatedTransValues?.meterType}
                  />
                  <SelectForm
                    id=""
                    options={billers}
                    label="Disco"
                    name="Distributor"
                    onChange={(e) => {
                      const disco = billers.find(
                        (item) => item.value === e.target.value
                      );
                      if (disco) {
                        dispatch(
                          setInitiatedTranValues({
                            ...initiatedTransValues,
                            distributor: disco.label,
                          })
                        );
                      }
                      dispatch(
                        setValidateCustomerDetails({
                          ...validateCustomerDetails,
                          billerCode: e.target.value,
                        })
                      );
                    }}
                    whiteInput
                    errorText={error.distributor}
                    selected={initiatedTransValues?.distributor}
                  />
                  <MoneyInput
                    label="Amount"
                    name="totalAmount"
                    whiteInput
                    onChange={(e) => {
                      setAmount(parseInt(e));
                      dispatch(
                        setInitiatedTranValues({
                          ...initiatedTransValues,
                          totalAmount: parseInt(e),
                        })
                      );
                    }}
                    value={amount > 0 ? amount : 0}
                    required
                    error={error.totalAmount}
                  />
                  <Input
                    label="Email"
                    whiteInput
                    name="email"
                    value={initiatedTransValues.email}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    label="Phone Number"
                    whiteInput
                    name="phoneNumber"
                    value={initiatedTransValues.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className={styles.center}>
                  <Button
                    text="Buy Electricity"
                    color="primary"
                    size="large"
                    type="submit"
                    className={styles.btn}
                    disabled={validatingCustomer}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className={styles.center}>
            <img src={goDown} alt="go down" />
          </div>
        </div>
        <div className={styles.bottom}>
          <ul>
            {/* <li>Our partners Include:</li> */}
            <li>
              <img src={nepa} alt="ekedc" />
            </li>
            <li>
              <img src={ikedc} alt="ikedc" />
            </li>
            <li>
              <img src={aedc} alt="aedc" />
            </li>
            <li>
              <img src={jdc} alt="jdc" />
            </li>
            <li>
              <img src={ibdc} alt="ibdc" />
            </li>
            <li>
              <img src={kedco} alt="kedco" />
            </li>
          </ul>
        </div>
      </div>
      {confirm && (
        <Modal
          onClose={() => {
            setConfirm(false);
            dispatch(setValidatedCustomer(false));
          }}
        >
          <ConfirmElectModal close={() => setConfirm(false)} />
        </Modal>
      )}
      {loadToken && (
        <Modal
          onClose={() => {
            dispatch(setLoadToken(false));
            setValidatedCustomer(false);
          }}
        >
          <div className={styles.myLoader}>
            <MyLoader />
          </div>
        </Modal>
      )}
      {validatingCustomer && (
        <Modal
          onClose={() => {
            dispatch(setValidatingCustomer(false));
            setValidatedCustomer(false);
          }}
        >
          <div className={styles.myLoader}>
            <MyLoader />
          </div>
        </Modal>
      )}
      {showTransReceipt && (
        <Modal
          onClose={() => {
            dispatch(setShowTransReceipt(false));
            dispatch(
              setInitiatedTranValues({
                meterType: "",
                distributor: "",
                totalAmount: 0,
                meterNumber: "",
                email: "",
                phoneNumber: "",
              } as InitiateTransValueType)
            );
          }}
        >
          {loadToken ? (
            <div className={styles.myLoader}>
              <MyLoader />
            </div>
          ) : (
            <Receipt transaction={billPaymentDetails} />
          )}
        </Modal>
      )}
    </>
  );
}

export default Header;
