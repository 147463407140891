import styles from "./Receipt.module.scss";
import logoDark from "assets/icons/logoDark.svg";
import classNames from "classnames";
import { formatCurrency } from "utils/formatter";
import { BillPaymentType } from "types/transaction";
import { TransactionContext } from "context/TransactionContext";
import { useContext, useEffect } from "react";
import { useAppSelector } from "app/hooks";

interface ReceiptType {
  transaction: BillPaymentType;
}

const Receipt = ({ transaction }: ReceiptType) => {
  const { calculateVat, calculateFlutterCharge } =
    useContext(TransactionContext);

  const { totalAmount } = useAppSelector((state) => state.transactions);

  useEffect(() => {
    if (transaction?.amount) calculateFlutterCharge(transaction.amount);
  }, [transaction, calculateFlutterCharge]);

  return (
    <>
      <div className={styles.transactionDetails}>
        <div className={styles.center}>
          <img src={logoDark} alt="pawa360" className={styles.logo} />
          {/* <p>
          {transaction?.dateCreated &&
            formatDate({ val: transaction?.dateCreated, fullDate: true })}
        </p> */}
        </div>

        {transaction?.token ? (
          <div className={styles.token}>
            <h3>{transaction?.token}</h3>
          </div>
        ) : (
          <div className={styles.token}>
            <h5 style={{ fontSize: "14px" }}>
              No token generated. Please, contact Support.
            </h5>
          </div>
        )}
        <div className={classNames([styles.item])}>
          <div className={classNames([styles.flexItem, styles.margin10])}>
            <p className={styles.title}>Customer Name</p>
            <p className={styles.text}>{transaction?.customerName}</p>
          </div>
          <div className={classNames([styles.flexItem])}>
            <p className={styles.title}>Address</p>
            <p className={styles.text}>{transaction?.address}</p>
          </div>
        </div>
        <div className={classNames([styles.flexRow, styles.item])}>
          <p className={styles.title}>Meter Number</p>
          <p className={styles.text}>{transaction?.meterNumber}</p>
        </div>
        <div className={classNames([styles.item])}>
          <div className={classNames([styles.flexRow])}>
            <p className={styles.title}>Electricity Amount</p>
            <p className={styles.text}>
              NGN {transaction?.amount && transaction.amount}
            </p>
          </div>
          {/* <div className={classNames([styles.flexRow])}>
            <p className={styles.title}>Unit value</p>
            <p className={styles.text}>
              {transaction?.units && transaction.units} units
            </p>
          </div> */}
          <div className={classNames([styles.flexRow])}>
            <p className={styles.title}>VAT</p>
            <p className={styles.text}>
              NGN{" "}
              {totalAmount > 0 &&
                formatCurrency(calculateVat(totalAmount).toString())}
            </p>
          </div>{" "}
          <div className={classNames([styles.flexRow])}>
            <p className={styles.title}>Service charge</p>
            <p className={styles.text}> NGN 100</p>
          </div>
          <div className={classNames([styles.flexRow])}>
            <p className={styles.title}>Total</p>
            <p className={styles.text}>
              NGN {totalAmount > 0 && formatCurrency(totalAmount.toString())}
            </p>
          </div>
        </div>

        <div className={classNames([styles.flexRow])}>
          <p className={styles.title}>Operator</p>
          <p className={styles.text}>Pawa360</p>
        </div>
        <p className={styles.bottomText}>Thank you for your purchase.</p>
        <div className={styles.center}>
          <img src={logoDark} alt="pawa360" className={styles.logo} />
        </div>
      </div>
      {/* <div className={styles.btnContainer}>
        <Button text="Send to Email" color="dark" disabled />
        <Button text="Download" color="dark" />
      </div> */}
    </>
  );
};

export default Receipt;
