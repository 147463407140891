import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SelectOptionType } from "types/shared";
import {
  BillPaymentType,
  InitiatedTransactionType,
  InitiateTransValueType,
  RequestedDetails,
  SendRequestType,
  TransactionHistoryType,
  TransactionType,
  ValidateCustomer,
  ValidateCustomerType,
} from "types/transaction";

interface transactionType {
  transactions: TransactionType[];
  transHistory: TransactionHistoryType;
  transaction: TransactionType;
  transLoading: boolean;
  customerDetails: ValidateCustomer;
  billers: SelectOptionType[];
  validatedCustomer: boolean;
  validatingCustomer: boolean;
  buyButtonLoading: boolean;
  initiatedTransValues: InitiateTransValueType;
  initiatedTransaction: InitiatedTransactionType;
  billPaymentDetails: BillPaymentType;
  validateCustomerDetails: ValidateCustomerType;
  showTransReceipt: boolean;
  loadToken: boolean;
  transactionMonths: string[];
  transactionAmounts: number[];
  sendRequest: SendRequestType;
  buyState: string;
  reorder: boolean;
  totalAmount: number;
  requestedDetails: RequestedDetails;
}

const initialState: transactionType = {
  transactions: [],
  transHistory: {} as TransactionHistoryType,
  transaction: {} as TransactionType,
  transLoading: false,
  customerDetails: {} as ValidateCustomer,
  billers: [],
  validatedCustomer: false,
  validatingCustomer: false,
  buyButtonLoading: false,
  initiatedTransValues: {
    meterType: "",
    distributor: "",
    totalAmount: 0,
    meterNumber: "",
    email: "",
    phoneNumber: "",
  } as InitiateTransValueType,
  initiatedTransaction: {} as InitiatedTransactionType,
  billPaymentDetails: {} as BillPaymentType,
  validateCustomerDetails: {} as ValidateCustomerType,
  showTransReceipt: false,
  loadToken: false,
  transactionMonths: [],
  transactionAmounts: [],
  sendRequest: {} as SendRequestType,
  buyState: "check",
  reorder: false,
  totalAmount: 0,
  requestedDetails: {} as RequestedDetails,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<TransactionType[]>) => {
      state.transactions = action.payload;
    },
    setTransHistory: (state, action: PayloadAction<TransactionHistoryType>) => {
      state.transHistory = action.payload;
    },
    setTransaction: (state, action: PayloadAction<TransactionType>) => {
      state.transaction = action.payload;
    },
    setTransLoading: (state, action: PayloadAction<boolean>) => {
      state.transLoading = action.payload;
    },
    setValidatedCustomerDetails: (
      state,
      action: PayloadAction<ValidateCustomer>
    ) => {
      state.customerDetails = action.payload;
    },
    setBillers: (state, action: PayloadAction<SelectOptionType[]>) => {
      state.billers = action.payload;
    },
    setValidatingCustomer: (state, action: PayloadAction<boolean>) => {
      state.validatingCustomer = action.payload;
    },
    setValidatedCustomer: (state, action: PayloadAction<boolean>) => {
      state.validatedCustomer = action.payload;
    },
    setLoadBuyButton: (state, action: PayloadAction<boolean>) => {
      state.buyButtonLoading = action.payload;
    },
    setInitiatedTranValues: (
      state,
      action: PayloadAction<InitiateTransValueType>
    ) => {
      state.initiatedTransValues = action.payload;
    },
    setInitiatedTransaction: (
      state,
      action: PayloadAction<InitiatedTransactionType>
    ) => {
      state.initiatedTransaction = action.payload;
    },
    setSuccessTransactionDetails: (
      state,
      action: PayloadAction<BillPaymentType>
    ) => {
      state.billPaymentDetails = action.payload;
    },
    setValidateCustomerDetails: (
      state,
      action: PayloadAction<ValidateCustomerType>
    ) => {
      state.validateCustomerDetails = action.payload;
    },
    setShowTransReceipt: (state, action: PayloadAction<boolean>) => {
      state.showTransReceipt = action.payload;
    },
    setLoadToken: (state, action: PayloadAction<boolean>) => {
      state.loadToken = action.payload;
    },
    setTransactionMonths: (state, action: PayloadAction<string[]>) => {
      state.transactionMonths = action.payload.reverse();
    },
    setTransactionAmounts: (state, action: PayloadAction<number[]>) => {
      state.transactionAmounts = action.payload.reverse();
    },
    setSendRequest: (state, action: PayloadAction<SendRequestType>) => {
      state.sendRequest = action.payload;
    },
    setBuyState: (state, action: PayloadAction<string>) => {
      state.buyState = action.payload;
    },
    setReorder: (state, action: PayloadAction<boolean>) => {
      state.reorder = action.payload;
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload;
    },
    setRequestedDetails: (state, action: PayloadAction<RequestedDetails>) => {
      state.requestedDetails = action.payload
    }
  },
});

export const {
  setTransactions,
  setTransHistory,
  setTransaction,
  setTransLoading,
  setValidatedCustomerDetails,
  setBillers,
  setValidatedCustomer,
  setValidatingCustomer,
  setLoadBuyButton,
  setInitiatedTranValues,
  setInitiatedTransaction,
  setSuccessTransactionDetails,
  setValidateCustomerDetails,
  setShowTransReceipt,
  setLoadToken,
  setTransactionAmounts,
  setTransactionMonths,
  setSendRequest,
  setBuyState,
  setReorder,
  setTotalAmount,
  setRequestedDetails
} = transactionsSlice.actions;
export const selectCount = (state: RootState) => state.transactions;

export default transactionsSlice.reducer;
