import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import styles from "./Nav.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import classNames from "classnames";
import { handleButtonClick } from "analytics";

type MobileNavProps = {
  close: (val: boolean) => void;
};
function MobileNav({ close }: MobileNavProps) {
  const navigate = useNavigate();
  return (
    <div className={styles.mobileNav}>
      <div className={styles.mobileNavIcon}>
        <Logo className={styles.logoLight} />
        <Close onClick={() => close(false)} />
      </div>
      <ul className={styles.mobileNavLinks}>
        <li>
          <Link
            to={"/"}
            onClick={() => {
              close(false);
              handleButtonClick("Navigated to Home page");
            }}
          >
            {" "}
            Home
          </Link>
        </li>
        {/* <li>How We Work</li>
        <li>About Us</li> */}
        <li>
          <Link
            to={"/#contact_us"}
            onClick={() => {
              close(false);
              handleButtonClick("Navigated to Contact Us form");
            }}
          >
            {" "}
            Contact
          </Link>
        </li>
      </ul>
      <div className={classNames([styles.flexRow, styles.btn])}>
        {" "}
        <Link
          to="/login"
          onClick={() => handleButtonClick("Navigated to Login page")}
        >
          {" "}
          Login
        </Link>
        <Button
          text="sign up"
          color="primary"
          onClick={() => {
            navigate("/signup");
            handleButtonClick("Navigated to Sign Up page");
          }}
        />
      </div>
    </div>
  );
}

export default MobileNav;
