import * as Spinners from "react-loader-spinner";

type MyLoaderType = {
  className?: string;
};

const MyLoader = ({ className }: MyLoaderType) => {
  return (
      <Spinners.Oval
        height="40"
        width="80"
        color="#ffe922"
        ariaLabel="loading"
        secondaryColor="white"
      />
  );
};

export default MyLoader;