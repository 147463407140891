import { putRequest } from "api/api";
import { setUserProfile, setProfileState } from "app/features/profile";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Input from "components/Input/Input";
import RadioButton from "components/RadioButton/RadioButton";
import { useState, useContext } from "react";
import styles from "./Profile.module.scss";
import toast from "react-hot-toast";
import { ProfileContext } from "context/ProfileContext";

function EditProfile() {
  const dispatch = useAppDispatch();
  const { fetchUserProfile } = useContext(ProfileContext);
  const { userProfile } = useAppSelector((state) => state.profile);
  const [firstName, setFirstName] = useState(
    userProfile.name ? userProfile.name.split(" ")[0] : ""
  );
  const [lastName, setLastName] = useState(
    userProfile.name ? userProfile.name.split(" ")[1] : ""
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      setUserProfile({
        ...userProfile,
        [name]: value,
      })
    );
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const newData = { ...userProfile, name: firstName + " " + lastName };
      const { data } = await putRequest("user/profile", newData);
      console.log(data);
      toast.success("Your profile has been updated!");
      fetchUserProfile();
      dispatch(setProfileState("view"));
    } catch (err) {
      const error = err as AxiosError;
      console.error("profile edit", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className={classNames([styles.padding40, styles.editContainer])}>
      <div className={classNames([styles.grid3, styles.top])}>
        <Input
          label="First Name"
          value={firstName}
          name="firstName"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          label="Last Name"
          value={lastName}
          name="lastName"
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          label="Meter Number"
          value={userProfile.meterNumber}
          name="meterNumber"
          onChange={handleChange}
        />
        <Input
          label="Email"
          value={userProfile.email}
          name="email"
          onChange={handleChange}
        />
        <Input
          label="Phone Number"
          value={userProfile.phoneNumber}
          name="phoneNumber"
          onChange={handleChange}
        />
        <Input
          label="Address"
          value={userProfile.address}
          name="address"
          onChange={handleChange}
        />
      </div>

      <div className={classNames([styles.justifyEnd, styles.btnContainer])}>
        <Button
          text="Back"
          color="dark"
          onClick={() => dispatch(setProfileState("view"))}
          disabled={loading}
        />
        <Button
          text="Save"
          color="dark"
          onClick={handleEdit}
          disabled={loading}
        />
      </div>
    </Card>
  );
}

export default EditProfile;
