import classNames from "classnames";
import styles from "./Select.module.scss";

interface optionProp {
  label?: string;
  value: string | number;
}

interface SelectFormProps {
  label?: string;
  name?: string;
  id: string;
  required?: boolean;
  placeholder?: string;
  options: optionProp[];
  errorText?: string;
  noMargin?: boolean;
  selectClass?: string;
  disabledOption?: string;
  value?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  children?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  prefix?: React.DetailedHTMLProps<React.HTMLAttributes<any>, any>;
  className?: string | string[];
  whiteInput?: boolean;
  selected?: string;
}

const SelectForm = ({
  id,
  label,
  options,
  disabledOption,
  errorText,
  prefix,
  noMargin = false,
  children,
  selectClass = "",
  className = [],
  name,
  required,
  whiteInput,
  selected,
  value,
  ...props
}: SelectFormProps) => {
  const classes = typeof className === "string" ? [className] : className;

  let errorClass = null;
  let noMarginClass = null;
  let prefixClass = null;

  if (errorText && errorText?.length > 0) {
    errorClass = styles["select--error"];
  }

  if (noMargin) {
    noMarginClass = styles["form_control--no-margin"];
  }

  if (prefix) {
    prefixClass = styles["select--prefix"];
  }

  return (
    <div
      className={classNames([styles.form_control, noMarginClass, ...classes])}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className={classNames([
          styles.select,
          errorClass,
          prefixClass,
          selectClass,
          whiteInput ? styles.whiteInput : "",
        ])}
        name={name}
        {...props}
        value={value}
      >
        {/* {disabledOption && (
              <option value={undefined} selected disabled>
                {disabledOption}
              </option>
            )} */}
        <option>Select {label}</option>
        {options &&
          options.map((option, index) => (
            <option
              key={index}
              value={`${option.value}`}
              selected={selected === option.value || selected === option.label}
            >
              {option.label || option.value}
            </option>
          ))}
      </select>

      {errorText && (
          <span className={classNames([styles.error_message])}>
            {errorText}
          </span>
        )}
      {/* {children} */}
    </div>
  );
};

export default SelectForm;
