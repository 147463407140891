import classNames from "classnames";
import styles from "./Card.module.scss";

type CardProps = {
  children: any;
  className?: string | undefined;
};

function Card({ children, className }: CardProps) {
  return (
    <div
      className={classNames([
        styles.card,
        className,
      ])}
    >
      {children}
    </div>
  );
}

export default Card;
