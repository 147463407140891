import { setProfileState } from "app/features/profile";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import EditProfile from "./EditProfile";
import ProfileView from "./ProfileView";
import { useContext, useEffect } from "react";
import { ProfileContext } from "context/ProfileContext";

function Profile() {
  const { profileState } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const {fetchUserProfile} = useContext(ProfileContext)

  
  useEffect(() => {
    fetchUserProfile();
  }, []);
  return (
    <DashboardLayout
      breadcrumb={profileState === "view" ? "Profile" : "Edit Profile"}
      click={() => dispatch(setProfileState("view"))}
      title={profileState === "view" ? "Profile" : "Edit Profile"}
    >
      {profileState === "view" && <ProfileView />}
      {profileState === "edit" && <EditProfile />}
    </DashboardLayout>
  );
}

export default Profile;