import { useAppSelector } from "app/hooks";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import BuyElectricity from "./BuyElectricity";
import CheckDetails from "./CheckDetails";
import RequestElectricity from "./RequestElectricity";

function Electricity() {
  const { electricityState } = useAppSelector((state) => state.electricity);
  return (
    <DashboardLayout
      breadcrumb="Electricity"
      breadcrumbLink="electricity"
      title="Electricity"
      center={electricityState === "buy" ? false : true}
    >
      {electricityState === "buy" && <BuyElectricity />}
      {electricityState === "checkDetails" && <CheckDetails />}
      {electricityState === "request" && <RequestElectricity />}
    </DashboardLayout>
  );
}

export default Electricity;
