import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import styles from "./Table.module.scss";

type TableProps = {
  title: string;
  tableHead?: string[];
  children: any;
};

function Table({ children, tableHead, title }: TableProps) {
  const navigate = useNavigate();
  return (
    <div className={styles.table}>
      <div className={styles.tableTitle}>
        <h5>{title}</h5>
        <Button
          text="View All"
          color="tertiary"
          size="small"
          onClick={() => navigate("/transactions")}
        />
      </div>
      <table>
        <thead>
          <tr>
            {tableHead?.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

export default Table;
