import { Routes, Route, useLocation } from "react-router-dom";
import DashboardHome from "pages/Dashboard/Home/DashboardHome";
import Electricity from "pages/Dashboard/Electricity/Electricity";
import Transaction from "pages/Dashboard/Transaction/Transactions";
import DashNotification from "pages/Dashboard/Notification/Notification";
import Profile from "pages/Dashboard/Profile/Profile";
import TransactionDetails from "pages/Dashboard/TransactionDetails/TransactionDetails";
import Landing from "pages/Landing/Landing";
import PrivateRoute from "routes/PrivateRoute";
import { useContext, useEffect } from "react";
import { AuthContext } from "context/AuthContext";
import RequestedElectricity from "pages/RequestedElectricity/RequestedElectricity";
import { trackMixpanelEvent, trackPageView } from "analytics";
import ConfirmDetails from "pages/ConfirmDetails/ConfirmDetails";
import Policy from "pages/Policy&Terms/Policy";
import Terms from "pages/Policy&Terms/Terms";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register/Register";
import Verify from "pages/Auth/Register/Verify";

function App() {
  const { setAuthToken } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    // Track page views with Mixpanel and Google Analytics
    const currentPage = location.pathname;
    trackPageView(currentPage);
    trackMixpanelEvent(`Viewed ${currentPage}`, { page: currentPage });
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setAuthToken(token);
  }, [setAuthToken]);

  useEffect(() => {
    const lightFavicon =
      "https://res.cloudinary.com/ddrjp7e3l/image/upload/v1728584220/Pawa360_-_Black_qmfahv.png";
    const darkFavicon =
      "https://res.cloudinary.com/ddrjp7e3l/image/upload/v1728584220/Pawa360_-_White_kawvlr.png";

    const updateFavicon = (theme: "dark" | "light") => {
      const favicon =
        document.querySelector<HTMLLinkElement>('link[rel="icon"]');
      if (favicon) {
        favicon.href = theme === "dark" ? darkFavicon : lightFavicon;
      }
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    updateFavicon(mediaQuery.matches ? "dark" : "light");

    mediaQuery.addEventListener("change", (e) => {
      updateFavicon(e.matches ? "dark" : "light");
    });

    return () => {
      mediaQuery.removeEventListener("change", () => {});
    };
  }, []);
  return (
    <>
      <Routes>
        <Route path="/verify" element={<Verify />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/" element={<Landing />} />
        <Route path="/confirm-details" element={<ConfirmDetails />} />
        <Route path="/request" element={<RequestedElectricity />} />
        {/* <Route path="/" element={<ComingSoon />} /> */}
        {/* <Route
        path="*"
        element={<Navigate to="/" replace />}
    /> */}
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/terms-of-use" element={<Terms />} />

        <Route
          path="/dashboard"
          element={<PrivateRoute Component={DashboardHome} />}
        />
        <Route path="/profile" element={<PrivateRoute Component={Profile} />} />
        <Route
          path="/electricity"
          element={<PrivateRoute Component={Electricity} />}
        />
        <Route
          path="/transactions"
          element={<PrivateRoute Component={Transaction} />}
        />
        <Route
          path="/transactions/:id"
          element={<PrivateRoute Component={TransactionDetails} />}
        />
        <Route
          path="/notification"
          element={<PrivateRoute Component={DashNotification} />}
        />
      </Routes>
      <div className="App">
        <TawkMessengerReact
          propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
          widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
        />
      </div>
    </>
  );
}

export default App;
