import logo from "assets/icons/logoDark.svg";
import classNames from "classnames";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import Button from "components/Button/Button";
import logoDark from "assets/icons/logoDark.svg";
import { ReactComponent as Menu } from "assets/icons/menu-icon.svg";
import { useContext, useEffect, useState } from "react";
import { dashboardLiks } from "./NavLinks";
import DashBoardMobile from "./DashBoardMobile";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AuthContext, AuthContextProvider } from "context/AuthContext";
import {
  setInitiatedTranValues,
  setReorder,
  setTransaction,
  setValidatedCustomer,
} from "app/features/transactions";
import { InitiateTransValueType, TransactionType } from "types/transaction";
import { ProfileContextProvider } from "context/ProfileContext";
import { setElectricityState } from "app/features/electricity";
import { setAutoLogout } from "utils/autoLogout";
import { handleButtonClick, identifyUserInMixpanel } from "analytics";

type DashboardType = {
  children: any;
  breadcrumb: string;
  breadcrumbLink?: string;
  title: string;
  click?: () => void;
  center?: boolean;
  hideBuy?: boolean;
};

function DashboardLayout({
  children,
  breadcrumb,
  title,
  breadcrumbLink,
  click,
  center = false,
  hideBuy = false,
}: DashboardType) {
  const { handleLogout } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { userProfile } = useAppSelector((state) => state.profile);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [shownav, setShowNav] = useState(false);

  const handleSignOut = () => {
    handleLogout();
    handleButtonClick("Log out button Clicked");
    navigate("/login");
  };

  useEffect(() => {
    if (token) {
      setAutoLogout(token, handleSignOut);
    }
  }, [token]);
  useEffect(() => {
    if (userProfile.id) {
      identifyUserInMixpanel(userProfile?.id.toString() || "", {
        name: userProfile?.name,
        email: userProfile?.email,
        phone: userProfile?.phoneNumber,
      });
    }
  }, [userProfile]);

  return (
    <AuthContextProvider>
      <ProfileContextProvider>
        <div className={styles.dashboard}>
          <div className={styles.sideNav}>
            <div>
              <div className={styles.dashLogo}>
                <img src={logo} alt="logo" />
              </div>
              <ul className={styles.sideNavLinks}>
                {dashboardLiks.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={item.link}
                      target={item.name === "Contact Us" ? "_blank" : "_self"}
                      onClick={() =>
                        handleButtonClick(
                          `Navigated to ${item.name} page`,
                          `${item.name}`
                        )
                      }
                    >
                      {({ isActive }) => {
                        return isActive ? (
                          <div
                            className={classNames([
                              styles.dashLinks,
                              styles.active,
                            ])}
                          >
                            {item.activeIcon}
                            {item.name}
                          </div>
                        ) : (
                          <div className={styles.dashLinks}>
                            {item.icon}
                            {item.name}
                          </div>
                        );
                      }}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.logoutBtn}>
              <Button
                text="Log Out"
                size="large"
                color="dark"
                onClick={handleSignOut}
              />
            </div>
          </div>
          <div className={styles.mobileNav}>
            <Link
              to="/"
              onClick={() =>
                handleButtonClick("Navigated to Home page", "Pawa360 Icon")
              }
            >
              <img src={logoDark} alt="pawa360" className={styles.logoDark} />
            </Link>

            <div className={styles.menu}>
              <Menu onClick={() => setShowNav(true)} />
            </div>
          </div>
          <div className={styles.dashboardRight}>
            <div className={styles.flex}>
              <div>
                {/* <Breadcrumb
                  text={breadcrumb}
                  link={breadcrumbLink}
                  click={click}
                /> */}
                <h4>{title}</h4>
              </div>
              <Button
                text="Buy Electricity"
                color="primary"
                size="large"
                className={hideBuy ? styles.hide : styles.buyBtn}
                onClick={() => {
                  handleButtonClick(
                    "Navigated to Electricity Page",
                    "Buy Electricity"
                  );
                  dispatch(setTransaction({} as TransactionType));
                  dispatch(
                    setInitiatedTranValues({
                      meterType: "",
                      distributor: "",
                      totalAmount: 0,
                      meterNumber: "",
                      email: "",
                      phoneNumber: "",
                    } as InitiateTransValueType)
                  );
                  dispatch(setReorder(false));
                  dispatch(setElectricityState("buy"));
                  navigate("/electricity");
                  dispatch(setValidatedCustomer(false));
                }}
              />
              {/* <Search /> */}
            </div>
            <div className={classNames([styles.flex, styles.bottom])}>
              <h5 className={styles.name}>
                {userProfile?.name
                  ? `Welcome, ${userProfile.name.split(" ")[0]}`
                  : "Hi There,"}
              </h5>
            </div>
            <div className={center ? styles.center : ""}>{children}</div>
          </div>
        </div>
        {shownav && (
          <DashBoardMobile
            close={() => {
              setShowNav(false);
            }}
          />
        )}
      </ProfileContextProvider>
    </AuthContextProvider>
  );
}

export default DashboardLayout;
