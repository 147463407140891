import styles from "./OtherSignin.module.scss";
import { useState, useEffect, useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode, JwtPayload as JwtLibraryPayload } from "jwt-decode";
import { useAppDispatch } from "app/hooks";
import { AuthContext } from "context/AuthContext";
import toast from "react-hot-toast";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { postRequest } from "api/api";
import {
  setToken,
  setUser,
  setVerifiedUser,
} from "app/features/auth";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function OtherSignin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const { setAuthToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  interface JwtPayload extends JwtLibraryPayload {
    name?: string;
    email?: string;
    role?: string;
    email_verified?: boolean;
    given_name: string;
    family_name: string;
  }
  const handleGoogleLogin = async (value: string) => {
    setLoading(true);
    try {
      const { data, status } = await postRequest("user/google/login", {
        tokenId: value,
      });
      if (status === 200) {
        dispatch(setToken(data.token));
        dispatch(setVerifiedUser(data));
        dispatch(setUser(data.email));
        localStorage.setItem("token", data.token);
        setAuthToken(data.token);
        navigate("/dashboard");
        toast.success("You have logged in successfully!");
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.status === 400) {
        toast.error(
          "We are unable to log you in with Google because you have not signed up. Please go to the Signup page and sign up with Google. Thank you."
        );
      }
      // console.log("google login", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGoogleSignup = async (value: JwtPayload) => {
    setLoading(true);
    try {
      const { status } = await postRequest("user/google/signup", {
        email: value.email,
        givenName: value.given_name,
        familyName: value.family_name,
      });
      if (status === 200) {
        dispatch(setUser(value.email!));
        navigate("/verify");
        toast.success("Please check your email for your OTP");
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.status === 400) {
        toast.error("This user already exists");
      } else {
        toast.error("couldn't signup with google");
      }
      // console.log("google signup", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.otherSignin}>
      <div className={styles.option}>
        <span></span>
        <p className={styles.or}>{isMobile ? "Or sign in with" : "Or"}</p>
        <span></span>
      </div>
      <div className={styles.btn}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const decode = jwtDecode(
              credentialResponse?.credential!
            ) as JwtPayload;
            if (decode?.email_verified) {
              if (location.pathname === "/login") {
                handleGoogleLogin(credentialResponse?.credential!);
              } else {
                handleGoogleSignup(decode);
              }
            }
          }}
          onError={() => {
            toast.error(
              "Sorry. We are unable to log you in at this time. Please contact Support"
            );
          }}
          size="large"
          logo_alignment={"center"}
          text={location.pathname === "/login" ? "signin_with" : "signup_with"}
          width={"380px"}
        />
        {/* <LoginSocialFacebook
          appId=""
          onResolve={(response) => console.log(response)}
          onReject={(error) => console.log(error)}
        >
          <Button
            text={isMobile ? "Facebook" : "Sign in with Facebook"}
            color="social"
            className={styles.socialButton}
            icon={facebook}
          />
        </LoginSocialFacebook> */}
        {/* <Button
          text={isMobile ? "Facebook" : "Sign in with Facebook"}
          color="social"
          size="large"
          className={styles.socialButton}
          icon={facebook}
        />{" "}
        <Button
          text={isMobile ? "Google" : "Sign in with Google"}
          color="social"
          size="large"
          className={styles.socialButton}
          icon={google}
        /> */}
      </div>
    </div>
  );
}

export default OtherSignin;
