import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth";
import dashboardReducer from "app/features/dashboard";
import profileReducer from "app/features/profile";
import electricityReducer from "app/features/electricity";
import transactionsReducer from "app/features/transactions";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    profile: profileReducer,
    electricity: electricityReducer,
    transactions: transactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
