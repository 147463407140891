import Layout from "layout/Layout";
import { ReactComponent as Logo } from "assets/icons/logoLarge.svg";
import styles from "./index.module.scss";
import Login from "./Login";
import Register from "./Register/Register";
import { useAppSelector } from "app/hooks";
import Verify from "./Register/Verify";

type AuthProps = {
  children?: any;
};

function Auth({ children }: AuthProps) {

  return (
    <Layout footerStyle={styles.footer}>
      <div className={styles.authContainer}>
        {children}
        <div className={styles.right}>
          <Logo className={styles.logoBig} />
        </div>
      </div>
    </Layout>
  );
}

export default Auth;
