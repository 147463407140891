import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!);
// export const initializeGA = () => {
//   ReactGA.initialize('YOUR_GA4_MEASUREMENT_ID'); // Replace with your Measurement ID
// };

ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID!);

// Define types for event properties to be used with Mixpanel and Google Analytics
interface EventProperties {
  [key: string]: string | number | boolean;
}

// Function to track events with Mixpanel
export const trackMixpanelEvent = (
  eventName: string,
  properties: EventProperties = {}
) => {
  mixpanel.track(eventName, properties);
};

// Function to identify user in Mixpanel and set user properties
export const identifyUserInMixpanel = (
  userId: string,
  properties: EventProperties = {}
) => {
  mixpanel.identify(userId);
  mixpanel.people.set(properties);
};

// Function to track page views with Google Analytics
export const trackPageView = (page: string) => {
  ReactGA.send({ hitType: "pageview", page });
};

// Function to track events with Google Analytics
export const trackGAEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const handleButtonClick = (text: string, btnName?: string) => {
  trackGAEvent("User Interaction", "Click", text);
  trackMixpanelEvent(text, { buttonName: btnName! });
};
