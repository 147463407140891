import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import styles from "./Modal.module.scss";
import { useContext } from "react";
import { TransactionContext } from "context/TransactionContext";
import { formatCurrency } from "utils/formatter";
import { setValidatedCustomer } from "app/features/transactions";
import { handleButtonClick } from "analytics";
type Props = {
  close: () => void;
};
function ConfirmElectModal({ close }: Props) {
  const dispatch = useAppDispatch();
  const { initiatedTransValues, customerDetails, buyButtonLoading } =
    useAppSelector((state) => state.transactions);
  const { handleInitiateTransaction } = useContext(TransactionContext);
  return (
    <Card className={classNames([styles.checkDetails, styles.padding40])}>
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.item}>
            <p className={styles.title}>Name</p>
            <p className={styles.text}>{customerDetails.name}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Address</p>
            <p className={styles.text}>{customerDetails.address}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Meter Number</p>
            <p className={styles.text}>{customerDetails.meterNumber}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Email</p>
            <p className={styles.text}>{initiatedTransValues.email}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Meter Type</p>
            <p className={styles.text}>Prepaid meter</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Phone Number</p>
            <p className={styles.text}>{initiatedTransValues.phoneNumber}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Disco</p>
            <p className={styles.text}>{initiatedTransValues.distributor}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Amount (**service charge of NGN 100)</p>
            <p className={styles.text}>
              NGN{" "}
              {formatCurrency((initiatedTransValues?.totalAmount).toString())}
            </p>
          </div>
        </div>
        {/* <div className={styles.paymentCont}>
          <p className={styles.text}>Select means of payment</p>
          <div className={classNames([styles.flex, styles.radio])}>
            <RadioButton label="Card" name="payment" />
            <RadioButton label="Bank Transfer" name="payment" />
            <RadioButton label="USSD" name="payment" />
          </div>
        </div> */}
        <div className={classNames([styles.btnContainer])}>
          <Button
            text="Back"
            color="dark"
            onClick={() => {
              dispatch(setValidatedCustomer(false));
              close();
            }}
          />
          <Button
            text="Proceed"
            color="dark"
            disabled={buyButtonLoading}
            onClick={() => {
              close();
              handleInitiateTransaction(initiatedTransValues);
              handleButtonClick("Confirmed Electricity Details", "Proceed");
            }}
          />
        </div>
      </div>
    </Card>
  );
}

export default ConfirmElectModal;
