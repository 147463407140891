import classNames from "classnames";
import Modal from "components/Modal/Modal";
import { Link, NavLink } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import { dashboardLiks } from "./NavLinks";
import arrow from "assets/icons/arrow-right.svg";
import copy from "assets/icons/copy.svg";

type DashMobileNavProps = {
  close: () => void;
};
function DashBoardMobile({ close }: DashMobileNavProps) {
  return (
    <Modal onClose={close}>
      <div className={styles.sideMobile}>
        <div>
          <div className={styles.header}>
            <p className={styles.welcomeText}>Welcome</p>
            <Link className={styles.nameProfile} to="/dashboard/profile">
              <h5>William Smith</h5>
              <img src={arrow} alt="arrow right" />
            </Link>
            <div className={styles.meter}>
              <p>Meter No.: 83927423837849</p>
              <img src={copy} alt="copy" />
            </div>
            <p className={styles.account}>1 Account . Personal</p>
          </div>
          <ul className={styles.sideNavLinks}>
            {dashboardLiks.map((item, index) => (
              <li key={index} onClick={() => close()}>
                <NavLink to={item.link}>
                  {({ isActive }) => {
                    return isActive ? (
                      <div
                        className={classNames([
                          styles.dashLinks,
                          styles.active,
                        ])}
                      >
                        {item.activeIcon}
                        {item.name}
                      </div>
                    ) : (
                      <div className={styles.dashLinks}>
                        {item.icon}
                        {item.name}
                      </div>
                    );
                  }}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}

export default DashBoardMobile;
