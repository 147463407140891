import classNames from "classnames";
import styles from "./Button.module.scss";

type ButtonProps = {
  text: string;
  color?: "primary" | "secondary" | "tertiary" | "default" | "social" | "dark";
  size?: "extraSmall" | "small" | "medium" | "large";
  disabled?: boolean;
  className?: string | undefined;
  icon?: string;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  id?: string;
};

function Button({
  text,
  color = "default",
  className,
  onClick,
  disabled = false,
  style,
  size = "medium",
  icon,
  type,
  id,
}: ButtonProps) {
  let disabledClass = "";
  let typeClass = "";
  let sizeClass = "";

  if (disabled) {
    disabledClass = styles["button--disabled"];
  }

  // set color class
  if (color !== "default") {
    typeClass = styles[`button--${color}`];
  }

  if (size !== "medium") {
    sizeClass = styles[`button--${size}`];
  }
  return (
    <button
      className={classNames([
        styles.button,
        typeClass,
        disabledClass,
        sizeClass,
        className,
      ])}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type={type}
      id={id}
    >
      {icon && <img src={icon} alt="" />}
      {text}
    </button>
  );
}

export default Button;
