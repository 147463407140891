import { jwtDecode, JwtPayload as JwtLibraryPayload } from "jwt-decode";

interface JwtPayload {
  exp: number; // Expiration time of the token
}

// Function to set a timeout for automatic logout
export const setAutoLogout = (token: string, logout: () => void) => {
  const decodedToken = jwtDecode(token) as JwtPayload;

  if (decodedToken && decodedToken.exp) {
    const expirationTime = decodedToken.exp * 1000 - Date.now(); // Convert exp to milliseconds

    if (expirationTime > 0) {
      // Set a timeout to log out the user when the token expires
      setTimeout(() => {
        logout();
      }, expirationTime);
    } else {
      // Token already expired, log out the user immediately
      logout();
    }
  }
};
