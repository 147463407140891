import back from "assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import styles from "./Back.module.scss";

type BackProps = {
  link?: string;
  onClick?: () => void;
};

function Back({ link, onClick }: BackProps) {
  const navigate = useNavigate();
  return (
    <div
      className={styles.back}
      onClick={onClick ? onClick : () => navigate(-1)}
    >
      <img src={back} alt="back" className={styles.backIcon} />
      <p>Back</p>
    </div>
  );
}

export default Back;
