import { useAppSelector } from "app/hooks";
import Card from "components/Card/Card";
import Table from "components/Table/Table";
import TableItem from "components/TableItem/TableItem";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import { Link, useNavigate } from "react-router-dom";
import styles from './Transactions.module.scss'

function Transaction() {
  const navigate = useNavigate();
  const { transactions, transLoading } = useAppSelector(
    (state) => state.transactions
  );
  return (
    <DashboardLayout
      breadcrumb="Transactions"
      breadcrumbLink="transactions"
      title="Transactions"
    >
      <Card>
        {transLoading ? (
          "Loading..."
        ) : (
          <Table title="Transactions History" tableHead={["", "", "", "", ""]}>
            {transactions.length > 0 ? (
                  transactions.map((item) => (
                    // <Link to={`/transactions/${item.id}`} key={item.id}>
                    <TableItem
                      tableData={item}
                      key={item.id}
                      onclick={() => navigate(`/transactions/${item.id}`)}
                    />
                    // </Link>
                  ))
                ) : (
                  <p className={styles.emptyTrans}>No Recent Transactions</p>
                )}
          </Table>
        )}
      </Card>
    </DashboardLayout>
  );
}

export default Transaction;
