import Button from "components/Button/Button";
import Input from "components/Input/Input";
import OtherSignin from "components/OtherSignin/OtherSignin";
import { useState } from "react";
import styles from "../index.module.scss";
import Auth from "../Auth";
import { useNavigate } from "react-router-dom";
import { postRequest } from "api/api";
import { setUser } from "app/features/auth";
import { useAppDispatch } from "app/hooks";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { handleButtonClick } from "analytics";

function Register() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [emailOrPhone, setEmailOrPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handleRegister = async (value: string) => {
    setLoading(true);
    try {
      const data = await postRequest("user/signup", {
        emailOrPhone: value,
      });
      if (data.status === 201) {
        dispatch(setUser(value));
        navigate("/verify");
        toast.success("Please check your email for your OTP");
      }
    } catch (err) {
      const error = err as AxiosError;
      const message: any = error.response?.data;
      toast.error(message.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Auth>
      <div className={styles.leftContainer}>
        <div className={styles.left}>
          <h1 className={styles.leftTitle}>Welcome 👋</h1>
          <form
            className={styles.leftContent}
            onSubmit={(e) => {
              e.preventDefault();
              handleRegister(emailOrPhone);
              handleButtonClick("Sign up Button Clicked", "Sign up");
            }}
          >
            <p className={styles.leftText}>
              Today is a new day. It's your day. You shape it. Sign up to start
              buying electricity.
            </p>
            <Input
              label="Email"
              value={emailOrPhone}
              name="emailOrPhone"
              onChange={(e) => {
                setEmailOrPhone(e.target.value);
              }}
              required
            />
            <div className={styles.forgot}>
              {/* <Link to="/">Forgot Email or Phone Number?</Link> */}
            </div>
            <Button
              text="Sign Up"
              color="secondary"
              size="large"
              className={styles.submitBtn}
              type="submit"
              disabled={loading}
            />
            <OtherSignin />
            <div className={styles.account}>
              <h6>You have an account?</h6>
              <p
                onClick={() => {
                  navigate("/login");
                  handleButtonClick("Navigated to Log in page", "Log in");
                }}
              >
                {" "}
                Log in
              </p>
            </div>
          </form>
        </div>
        <p className={styles.copyright}>© 2024 ALL RIGHTS RESERVED</p>
      </div>
    </Auth>
  );
}

export default Register;
