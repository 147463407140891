import { setProfileState } from "app/features/profile";
import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import styles from "./Profile.module.scss";

function ProfileView() {
  const dispatch = useAppDispatch();
  const {userProfile} = useAppSelector(state => state.profile)

  return (
    <Card className={styles.profileView}>
      <div className={styles.yourProfile}>
        <h3>Your Profile</h3>
      </div>
      <div className={styles.profileContainer}>
        <div className={styles.profileDetails}>
          <div className={classNames([styles.profileItem])}>
            <h5 className={styles.title}>Meter Number:</h5>
            <h5 className={styles.text}>{userProfile.meterNumber}</h5>
          </div>
          <div className={classNames([styles.profileItem])}>
            <h5 className={styles.title}>Name:</h5>
            <h5 className={styles.text}>{userProfile.name}</h5>
          </div>
          <div className={classNames([styles.profileItem])}>
            <h5 className={styles.title}>Address:</h5>
            <h5 className={styles.text}>{userProfile.address}</h5>
          </div>
          <div className={classNames([styles.profileItem])}>
            <h5 className={styles.title}>Contact No.:</h5>
            <h5 className={styles.text}>{userProfile.phoneNumber}</h5>
          </div>
          <div className={classNames([styles.profileItem])}>
            <h5 className={styles.title}>Email:</h5>
            <h5 className={styles.text}>{userProfile.email}</h5>
          </div>
        </div>
        <div className={styles.justifyEnd}>
          <Button
            text="Edit"
            onClick={() => dispatch(setProfileState("edit"))}
            color="dark"
          />
        </div>
      </div>
    </Card>
  );
}

export default ProfileView;
