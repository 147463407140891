interface FormatDateType {
  val: string;
  fullDate?: boolean;
}

export const formatCurrency = (amount: string) => {
  if (amount) {
    let decimalArr = amount.split(".");
    let arrAmount = decimalArr[0].split("");
    const size = arrAmount.length;

    if (size > 3) {
      let newArr2 = arrAmount.splice(size - 3, 3);
      newArr2.unshift(",");
      let arrAmount2 = arrAmount.join("");
      let result: any = formatCurrency(arrAmount2) + newArr2.join("");
      if (decimalArr.length === 2) {
        return result + "." + decimalArr[1];
      } else {
        return result;
      }
    }
    return amount;
  }
};
export const formatDate = ({ val, fullDate = false }: FormatDateType) => {
  const date = new Date(val);
  const currentDate = new Date();

  const timeDifference: any = currentDate.getTime() - date.getTime();
  const seconds = Math.floor(timeDifference / 1000);

  // Check if less than an hour
  if (fullDate) {
    const options: any = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  } else if (seconds < 60) {
    return `${seconds}s ago`; // If less than 60 seconds, return the time in seconds
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}min ago`; // If less than 60 minutes, return the time in minutes
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours}h ago`; // If more than an hour, return the time in hours
  } else {
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
};
// import numeral from 'numeral';

// interface formatCurrencyType {
//   amount: number | undefined;
//   shortened?: boolean;
//   hideCurrency?: boolean;
//   nairaOnly?: boolean;
//   koboOnly?: boolean;
// }

// export const formatCurrency = ({
//   amount = 0,
//   shortened = false,
//   hideCurrency = false,
//   nairaOnly = false,
//   koboOnly = false,
// }: formatCurrencyType): string => {
//   if (shortened) {
//     const format = nairaOnly ? '0a' : '0.0a';
//     let formatted = numeral(amount / 100).format(format, Math.floor);
//     if (!hideCurrency) {
//       formatted = `₦ ${formatted}`;
//     }
//     return formatted;
//   }

//   const formatted = `₦${numeral(amount / 100).format('0,0.00', Math.round)}`;
//   if (nairaOnly) {
//     const removeNaira = formatted.substring(1);
//     return removeNaira.split('.')[0];
//   }
//   if (koboOnly) {
//     const removeNaira = formatted.substring(1);
//     return `.${removeNaira.split('.')[1]}`;
//   }
//   if (hideCurrency) {
//     return formatted.substring(1);
//   }
//   return formatted;
// };

// export const parseCurrency = (amount: any) => {
//   if (String(amount) === 'NaN') {
//     return 0;
//   }
//   if (Number.isNaN(amount)) {
//     return parseFloat(amount.replace(/[^\d.-]/g, ''));
//   }

//   if (amount.length === 0) {
//     return 0;
//   }

//   return parseFloat(amount);
// };