import classNames from "classnames";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
// import RadioButton from "components/RadioButton/RadioButton";
import styles from "./Landing.module.scss";
import fullCircle from "assets/icons/fullCircle.svg";
import ellipse from "assets/icons/ellipse.svg";
import send from "assets/icons/send.svg";
import Card from "components/Card/Card";
import { ReactComponent as Twitter } from "assets/icons/twitter-black.svg";
import instagram from "assets/icons/instagram.svg";
import { Link, useLocation } from "react-router-dom";
import getStarted2 from "assets/icons/getStarted2.svg";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import axios from "axios";

function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_TOKEN; // Replace with your Airtable API Key
  const BASE_ID = process.env.REACT_APP_AIRTABLE_TABLE_ID; // Replace with your Airtable Base ID
  const TABLE_NAME = process.env.REACT_APP_AIRTABLE_CONTACT_TABLE_NAME;

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleContactUs = async () => {
    const data = {
      fields: {
        "First Name": formData.firstName,
        "Last Name": formData.lastName,
        "Email Address": formData.email,
        "Phone Number": formData.phoneNumber,
        Message: formData.message,
        // Assignee:"",
        // Status:"",
        // Notes:"",
        // Date:""
      },
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${AIRTABLE_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          "We have received your message. A member of the team will reach out shortly. Thank you!"
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error(
        "We are unable to receive your message at this time. Please, call our Support line"
      );
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <section className={styles.contactUs} id="contact_us">
      {/* <div className={classNames([styles.center, styles.tryPawa])}>
        <img src={getStarted2} alt="" />
        <h1>Get started for free</h1>
        <p>Play around with it first. Pay and add your account later.</p>
        <div className={styles.btnContainer}>
          <Button
            text="Try NoLight now"
            color="dark"
            className={styles.tryBtn}
          />
          <Link to="/">Request a help</Link>
        </div>
      </div> */}
      <div>
        <div className={classNames([styles.center, styles.title])}>
          <h1>Contact Us</h1>
          <p>Any question or remarks? Just write us a message!</p>
        </div>
        <Card className={styles.contactContainer}>
          {/* <div className={styles.contactDetails}>
            <div>
              <h4>Contact Information</h4>
              <p>Say something to start a live chat!</p>
            </div>

            <ul>
              <li>+1012 3456 789</li>
              <li>demo@gmail.com</li>
              <li>
                132 Dartmouth Street Boston, Massachusetts 02156 United States
              </li>
            </ul>
            <div className={styles.social}>
              <Twitter />
              <div className={styles.instagram}>
                <img src={instagram} alt="instagram" />
              </div>
            </div>
            <img src={fullCircle} alt="" className={styles.fullCircle} />
            <img src={ellipse} alt="" className={styles.ellipse} />
          </div> */}
          <form
            className={styles.contactForm}
            onSubmit={(e) => {
              e.preventDefault();
              handleContactUs();
            }}
          >
            <div className={classNames([styles.grid2, styles.input])}>
              <Input
                label="First Name"
                value={formData.firstName}
                name="firstName"
                onChange={updateInput}
                required={true}
              />
              <Input
                label="Last Name"
                value={formData.lastName}
                name="lastName"
                onChange={updateInput}
                required={true}
              />
              <Input
                label="Email Address"
                value={formData.email}
                name="email"
                onChange={updateInput}
                required={true}
              />
              <Input
                label="Phone Number"
                value={formData.phoneNumber}
                name="phoneNumber"
                onChange={updateInput}
                required={true}
              />
            </div>
            {/* <div className={styles.subject}>
              <p className={styles.smallText}>Select Subject?</p>
              <div className={classNames([styles.flex, styles.radio])}>
                <RadioButton label="General Inquiry" name="subject" />
                <RadioButton label="General Inquiry" name="subject" />
                <RadioButton label="General Inquiry" name="subject" />
                <RadioButton label="General Inquiry" name="subject" />
              </div>
            </div> */}
            <Input
              label="Message"
              value={formData.message}
              name="message"
              onChange={updateInput}
              required={true}
            />
            <div className={styles.justifyEnd}>
              <Button
                text="Send Message"
                color="dark"
                size="large"
                className={styles.btn}
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
          <img src={send} alt="" className={styles.send} />
        </Card>
      </div>
    </section>
  );
}

export default ContactUs;
