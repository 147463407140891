import { handleButtonClick } from "analytics";
import { setElectricityState } from "app/features/electricity";
import {
  setInitiatedTranValues,
  setReorder,
  setValidatedCustomer,
  setLoadToken
} from "app/features/transactions";
import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import MyLoader from "components/Loader/Loader";
import Modal from "components/Modal/Modal";
import { TransactionContext } from "context/TransactionContext";
import { useContext, useEffect } from "react";
import { InitiateTransValueType } from "types/transaction";
import { formatCurrency } from "utils/formatter";
import styles from "./Electricity.module.scss";

function CheckDetails() {
  const {
    handleInitiateTransaction,
    calculateElecCost,
    calculateFlutterCharge,
    calculateVat,
  } = useContext(TransactionContext);
  const dispatch = useAppDispatch();
  const {
    initiatedTransValues,
    customerDetails,
    buyButtonLoading,
    transaction,
    reorder,
    loadToken,
  } = useAppSelector((state) => state.transactions);

  // const amount =
  //   parseInt(initiatedTransValues?.totalAmount) +
  //   initiatedTransValues?.serviceChargeAmount;

  // console.log(amount);

  useEffect(() => {
    if (reorder)
      dispatch(
        setInitiatedTranValues({
          meterNumber: transaction.meterNumber,
          meterType: transaction.meterType,
          email: transaction.email,
          phoneNumber: transaction.phoneNumber,
          distributor: transaction.distributor,
          totalAmount: transaction.totalAmount,
          serviceChargeAmount: 100,
          providerChargeAmount: calculateFlutterCharge(transaction.totalAmount),
          billPurchaseAmount: calculateElecCost(transaction.totalAmount),
          vatChargeAmount: calculateVat(transaction.totalAmount),
        })
      );
  }, []);

  return (
    <Card className={classNames([styles.checkDetails, styles.padding40])}>
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.item}>
            <p className={styles.title}>Name</p>
            <p className={styles.text}>{customerDetails.name}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Address</p>
            <p className={styles.text}>{customerDetails.address}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Meter Number</p>
            <p className={styles.text}>{customerDetails.meterNumber}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Email</p>
            <p className={styles.text}>{initiatedTransValues.email}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Meter Type</p>
            <p className={styles.text}>Prepaid meter</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Phone Number</p>
            <p className={styles.text}>{initiatedTransValues.phoneNumber}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Disco</p>
            <p className={styles.text}>{initiatedTransValues.distributor}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Amount (**service charge of NGN 100)</p>
            <p className={styles.text}>
              NGN{" "}
              {reorder
                ? formatCurrency(transaction.totalAmount.toString())
                : initiatedTransValues?.totalAmount &&
                  formatCurrency(
                    (initiatedTransValues?.totalAmount).toString()
                  )}
            </p>
          </div>
        </div>
        {/* <div className={styles.paymentCont}>
          <p className={styles.text}>Select means of payment</p>
          <div className={classNames([styles.flex, styles.radio])}>
            <RadioButton label="Card" name="payment" />
            <RadioButton label="Bank Transfer" name="payment" />
            <RadioButton label="USSD" name="payment" />
          </div>
        </div> */}
        <div className={classNames([styles.btnContainer])}>
          <Button
            text="Back"
            color="dark"
            onClick={() => {
              dispatch(
                setInitiatedTranValues({
                  meterType: "",
                  distributor: "",
                  totalAmount: 0,
                  meterNumber: "",
                  email: "",
                  phoneNumber: "",
                } as InitiateTransValueType)
              );
              dispatch(setValidatedCustomer(false));
              dispatch(setElectricityState("buy"));
            }}
          />
          <Button
            text="Proceed"
            color="dark"
            disabled={buyButtonLoading}
            onClick={() => {
              handleInitiateTransaction(initiatedTransValues);
              dispatch(setReorder(false));
              handleButtonClick("Confirmed Electricity Details");
            }}
          />
        </div>
      </div>
      {loadToken && (
        <Modal onClose={()=>dispatch(setLoadToken(false))}>
          <div className={styles.myLoader}>
            <MyLoader />
          </div>
        </Modal>
      )}
    </Card>
  );
}

export default CheckDetails;
