import Footer from "./Footer/Footer";
import NavBar from "./Nav/Nav";

interface LayoutProps {
  children?: any;
  footerStyle?: string;
  page?: string
}

function Layout({ children, footerStyle, page }: LayoutProps) {
  return (
    <>
      <NavBar page={page} />
      {children}
      <Footer footerStyle={footerStyle} />
    </>
  );
}

export default Layout;
