import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "context/AuthContext";
import { Provider } from "react-redux";
import { store } from "app/store";
import { Toaster } from "react-hot-toast";
import { ProfileContextProvider } from "context/ProfileContext";
import { TransactionProvider } from "context/TransactionContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
root.render(
  <Provider store={store}>
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
          padding: 16,
          paddingLeft: 19,
          maxWidth: "100%",
          fontSize: "14px",
          fontWeight: "bold",
          marginTop: "30px",
        },
        error: {
          duration: 7000,
          style: {
            background: "#333",
            color: "#fff",
          },
          // icon: <ErrorIcon />,
        },
        success: {
          duration: 7000,
          style: {
            background: "#333",
            color: "#fff",
          },
          // icon: <SuccessIcon />,
        },
        loading: {
          style: {
            backgroundColor: "#5935D5",
          },
        },
      }}
    />
    <TransactionProvider>
      <AuthContextProvider>
        <ProfileContextProvider>
          <React.StrictMode>
            <GoogleOAuthProvider clientId={clientId ? clientId : ""}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </GoogleOAuthProvider>
          </React.StrictMode>
        </ProfileContextProvider>
      </AuthContextProvider>
    </TransactionProvider>
  </Provider>
);

reportWebVitals();