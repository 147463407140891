import { getRequest } from "api/api";
import { setUserProfile } from "app/features/profile";
import { useAppDispatch } from "app/hooks";
import { AxiosError } from "axios";
import { createContext, useCallback, useEffect } from "react";

interface ProfileContextType {
  fetchUserProfile: () => Promise<void>;
}

export const ProfileContext = createContext<ProfileContextType>(
  {} as ProfileContextType
);

interface Props {
  children: React.ReactNode;
}

export const ProfileContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const fetchUserProfile = async () => {
    try {
      const { data } = await getRequest("user/profile");
      dispatch(setUserProfile(data));
    } catch (err) {
      const error = err as AxiosError;
      // console.log(error);
    }
  };

  return (
    <ProfileContext.Provider value={{ fetchUserProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
