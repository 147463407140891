import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ProfileStateType, UserProfileType } from "../../types/profile";

interface profileType {
  profileState: ProfileStateType;
  userProfile: UserProfileType;
}

const initialState: profileType = {
  profileState: "view",
  userProfile: {} as UserProfileType,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfileState: (state, action: PayloadAction<ProfileStateType>) => {
      state.profileState = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserProfileType>) => {
      state.userProfile = action.payload;
    },
  },
});

export const { setProfileState, setUserProfile } = profileSlice.actions;
export const selectCount = (state: RootState) => state.profile;

export default profileSlice.reducer;
