import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { VerifyResponseType } from "types/auth";

interface authType {
  authState: string;
  user: string;
  token: string;
  verifiedUser: VerifyResponseType;
}

const initialState: authType = {
  authState: "login",
  user: "",
  token: "",
  verifiedUser: {} as VerifyResponseType,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<string>) => {
      state.authState = action.payload;
    },
    setUser: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setVerifiedUser: (state, action: PayloadAction<VerifyResponseType>) => {
      state.verifiedUser = action.payload;
    },
  },
});

export const { setAuthState, setUser, setToken, setVerifiedUser } = authSlice.actions;
export const selectCount = (state: RootState) => state.auth;

export default authSlice.reducer;
