import styles from "./Modal.module.scss";
import { ReactComponent as Close } from "assets/icons/close.svg";

type ModalProps = {
  children: any;
  onClose: () => void;
};

function Modal({ children, onClose }: ModalProps) {
  return (
    <div className={styles.modalContainer} role="presentation">
      <div className={styles.close}>
        <Close onClick={onClose} />
      </div>
      <div className={styles.modalBody}>{children}</div>
    </div>
  );
}

export default Modal;