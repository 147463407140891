import Modal from "./Modal";
import successIcon from "assets/icons/success.svg";
import error from "assets/icons/error.svg";
import Card from "components/Card/Card";
import Button from "components/Button/Button";
import styles from "./Modal.module.scss";
import { useAppDispatch } from "app/hooks";
import { setBuyState, setValidatedCustomer } from "app/features/transactions";
import { setElectricityState } from "app/features/electricity";

interface StatusModalProps {
  success?: boolean;
  text: string;
}

function StatusModal({ success, text }: StatusModalProps) {
  const dispatch = useAppDispatch();
  return (
    <Modal onClose={() => {}}>
      <Card className={styles.statusModal}>
        <img src={success ? successIcon : error} alt="" />
        {success ? <h4>Success!</h4> : <h4>Error!</h4>}
        <p>{text}</p>
        {/* <Button
          text={success ? "Continue" : "Try Again"}
          color="dark"
          onClick={() => {
            dispatch(setBuyState("buy"));
            dispatch(setValidatedCustomer(false));
            dispatch(setElectricityState("buy"));
          }}
        /> */}
      </Card>
    </Modal>
  );
}

export default StatusModal;
